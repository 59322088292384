import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from './bin/axios_gsh';
import Table from './components/Table';

interface OrderMeta {
  id: string;
  Status: string;
  DeliveryDate: string;
  ReceivedDateTime: string;
  Företag: string;
  FormateradFöretag: string;
  Email: string;
  Namn: string;
  FormateradNamn: string;
  Ordertyp: string;
  Price: string;
  Info: string;
}

interface OrderProduct {
  ID: string;
  Benämning: string | JSX.Element | null;
  Namn: string;
  QuantityValue: string;
  Pris: string | null;
  ProduktID: string;
  Total: string | null;
}

interface OrderError {
  title: string;
  description: string;
}

interface OrderResult {
  meta: OrderMeta;
  products: OrderProduct[];
  error?: OrderError | null;
}

interface OrderProps {
  id: number | null;
  skipDataColumns?: number[];
}

interface OrderState {
  skipDataColumns: number[];
  id: number | null;
  identifierIndex: number;
  headers: string[];
  result: OrderResult;
  data: OrderResult;
  hasLoaded: boolean;
  statuses?: string[];
}

class Order extends Component<OrderProps, OrderState> {
  constructor(props: OrderProps) {
    super(props);

    const mold = {
      meta: {
        id: 'Titel',
        Status: 'Status',
        DeliveryDate: '',
        ReceivedDateTime: '',
        Företag: '',
        FormateradFöretag: '',
        Email: '',
        Namn: '',
        FormateradNamn: '',
        Ordertyp: '',
        Price: '',
        Info: '',
    },
      products: [],
      error: null,
    };

    this.state = {
      id: this.props.id,
      identifierIndex: 0,
      headers: ['ID', 'Artikel', 'Enhet', 'Antal', 'Pris'],
      result: { ...mold },
      data: { ...mold },
      skipDataColumns: this.props.skipDataColumns || [0],
      hasLoaded: false,
    };

    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    const self = this;

    self.loadData(self.state.id).then(() => {
      self.updateData();
    });
    // axios.get('order/status').then((response) => {
    //   response = response.data;

    //   self.setState(
    //     {
    //       statuses: response,
    //     },
    //     () => {
    //       self.loadData(self.state.id).then(() => {
    //         self.updateData();
    //       });
    //     },
    //   );
    // });
  }

  componentDidUpdate(prevProps: OrderProps) {
    const self = this;
    if (!this.state.hasLoaded || prevProps.id !== this.props.id) {
      this.loadData(this.props.id).then(() => {
        self.updateData();
      });
    }
  }

  loadData(id: number | null) {
    const self = this;
    return axios.get<OrderResult>(`order/${id}`).then((response) => {
      const res = response.data as OrderResult;

      self.setState(
        {
          result: {
          meta: res.meta,
          products: res.products,
          error: res.error || null,
        },
          hasLoaded: true,
        },
        () => {

        },
      );
    });
  }

  updateData() {
    if (!this.state.result.error) {
      const meta = { ...this.state.result.meta };

      let date = new Date(meta.DeliveryDate);
      let day = date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : date.getUTCDate();
      let month = date.getUTCMonth() + 1 < 10
        ? `0${date.getUTCMonth() + 1}`
        : date.getUTCMonth() + 1;
      meta.DeliveryDate = `${day}/${month}/${date.getFullYear()}`;

      date = new Date(meta.ReceivedDateTime);
      day = date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : date.getUTCDate();
      month = date.getUTCMonth() + 1 < 10
        ? `0${date.getUTCMonth() + 1}`
        : date.getUTCMonth() + 1;
      meta.ReceivedDateTime = `Kl. ${
        date.getUTCHours() < 10
          ? `0${date.getUTCHours()}`
          : date.getUTCHours()
      }:${
        date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
      }, ${
        day
      }/${
        month
      }/${
        date.getUTCFullYear()}`;

      meta.Företag += ' ';
      meta.Namn = meta.Namn
        ? meta.Namn + (meta.Email ? ` (${meta.Email})` : '')
        : 'Inte tillgängligt';
      meta.Info = meta.Info ? meta.Info : '...';

      const products: Array<any> = [];
      this.state.result.products.forEach((row) => {
        const product = {
          ID: row.ID,
          Benämning: '' as React.ReactNode,
          Namn: row.Namn,
          QuantityValue: row.QuantityValue,
          Pris: '',
        };
        const orderLink = `/product/${row.ProduktID}`;

        product.Benämning = (
          <Link to={orderLink}>
            {' '}
            {row.Benämning}
            {' '}
          </Link>
        );
        
        product.Pris = row.Total
          ? `${parseFloat(row.Total).toFixed(2)} kr`
          : 'Inte tillgängligt';

        products.push(product);
      });

      this.setState({
        data: {
          meta,
          products,
        },
      });
    }
  }

  render() {
    const onclick = function () {};

    let total = 0.0;
    if (this.state.result.products) {
      this.state.result.products.forEach((product) => {
        if (product.Total !== null) {
          total += parseFloat(product.Total);
        }
      });
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h1>Order</h1>
            <span className="badge badge-secondary">
              {this.state.data.meta.Status}
            </span>
            {this.state.data.error && (
              <div className="error">
                <p>{this.state.data.error.title}</p>
                <p>{this.state.data.error.description}</p>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <dl>
              <dt>Skapad av</dt>
              <dd>
                {this.state.data.meta.Företag}
                <br />
                {this.state.data.meta.Namn}
              </dd>
              <dd />
            </dl>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <dl>
              <dt>Leveransdatum</dt>
              <dd>{this.state.data.meta.DeliveryDate}</dd>
            </dl>
          </div>
          <div className="col-lg-4 col-md-12">
            <dl>
              <dt>Orderdatum</dt>
              <dd>{this.state.data.meta.ReceivedDateTime}</dd>
            </dl>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <dl>
              <dt>Ordermeddelande</dt>
              <dd>{this.state.data.meta.Info}</dd>
            </dl>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="border rounded">
              <div className="px-0 col-md-12">
                <Table
                  hasLoaded={this.state.hasLoaded}
                  clickFunction={onclick}
                  visualFeedback={false}
                  countRows
                  headers={this.state.headers}
                  data={this.state.data.products}
                  skipDataColumns={this.state.skipDataColumns}
                  identifierIndex={this.state.identifierIndex}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-right">
            <div className="text-right pt-3">
              {`Totalt: ${
                total === 0.0
                  ? 'Inte tillgängligt'
                  : `${total.toFixed(2)} kr`}`}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Order;