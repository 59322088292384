import React, { ChangeEvent, Component, ReactNode } from 'react';

interface SearchBarProps {
  data?: any[]; 
  searchResult?: ReactNode;
  searchString?: string;
  showNumber?: number;
  clickFunction?: (e: any, object: any) => void; 
  inactivated?: boolean;
}

interface SearchBarState {
  data: any[]; 
  searchResult: ReactNode;
  searchString: string;
  showNumber: number;
  parentClickFunction: (e: any, object: any) => void; 
  disabled: boolean;
}

class SearchBar<T extends SearchBarState = SearchBarState> extends Component<SearchBarProps, T> {
  constructor(props: SearchBarProps) {
    super(props);
    this.state = {
      data: props.data || [],
      searchResult: props.searchResult || null,
      searchString: props.searchString || '',
      showNumber: props.showNumber || 50,
      parentClickFunction: props.clickFunction || (() => {}),
      disabled: props.inactivated || false,
    } as T;

    this.handleChange = this.handleChange.bind(this);
    this.setSearchString = this.setSearchString.bind(this);
    this.updateList = this.updateList.bind(this);
    this.clickSearch = this.clickSearch.bind(this);
  }

  static getDerivedStateFromProps(nextProps: SearchBarProps, prevState: SearchBarState): SearchBarState {
    const data = nextProps.data || prevState.data;
    const searchResult = nextProps.searchResult || prevState.searchResult;
    const searchString = nextProps.searchString || prevState.searchString;
    const showNumber = nextProps.showNumber || prevState.showNumber;
    const parentClickFunction = nextProps.clickFunction || prevState.parentClickFunction;
    const disabled = nextProps.inactivated || prevState.disabled;

    return {
      data,
      searchResult,
      searchString,
      showNumber,
      parentClickFunction,
      disabled,
    };
  }

  setSearchString(str: string) {
    this.setState({
      searchString: !this.state.disabled ? str : '',
    });
  }

  handleChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      searchString: !this.state.disabled ? event.target.value : '',
    }, () => {
      this.search();
    });
  }

  search() {
    // Override
  }

  clickSearch() {
    // Override
  }

  updateList() {
    // Override
  }

  render() {
    return (
      <div className="input-group input-group-lg mb-3 search-wrapper">
        <input
          type="text"
          className="form-control rounded-0"
          name="search"
          id="search"
          placeholder="Sök efter en artikel"
          value={this.state.searchString}
          onChange={this.handleChange}
          disabled={this.state.disabled}
          autoComplete="off"
        />
        <div className="list-group col-md-12 px-0 position-absolute" id="search-results">
          {this.state.searchResult}
        </div>
      </div>
    );
  }
}

export default SearchBar;
