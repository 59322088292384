import React, { ChangeEvent, FormEvent } from 'react';
import SmallLoading from './SmallLoading';

interface UpdateAmountFormProps {
  meta: {
    amount: string;
    unitID: number;
    Förpackningsstorlek: string;
    Ej_Delbar: boolean;
    Pris_per_styck: boolean;
    Stegom: number;
    productID: string;
  };
  buyingPieces: boolean;
  updateProductAmount: (productID: string, amount: string) => void;
  buyProductPiece: (productID: string, buyingPieces: boolean) => void;
  updateProduct: (oldProduct: any, newProduct: any) => void;
}

interface UpdateAmountFormState {
  amount: string;
  unit: string;
  tempAmount: string;
  tempUnit: number;

  infoMessage: string | null;
  valid: boolean;

  step: number;
  kg: number;
  st: number;
  kolli: number;

  Förpackningsstorlek: string;

  hasClicked: boolean;
  selectText: string;
  stage: number;

  bufferIndex: number;
  buyingPieces: boolean;

  loading: boolean;
}

class UpdateAmountForm extends React.Component<UpdateAmountFormProps, UpdateAmountFormState> {
  constructor(props: UpdateAmountFormProps) {
    super(props);
    this.state = {
      amount: '',
      unit: '',
      tempAmount: this.props.meta.amount,
      tempUnit: this.props.meta.unitID,

      infoMessage: null,
      valid: true,

      step: 1,
      kg: 1,
      st: 2,
      kolli: 3,

      Förpackningsstorlek: this.props.meta.Förpackningsstorlek,

      hasClicked: false,
      selectText:
        this.props.meta.unitID === 1
          ? 'Vikt'
          : this.props.meta.unitID === 2
            ? 'Antal'
            : 'Kolli',
      stage: 0,

      bufferIndex: 0,
      buyingPieces: this.props.buyingPieces,

      loading: false,
    };

    this.onClick = this.onClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeUnit = this.onChangeUnit.bind(this);
    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    let value = 1;
    switch (this.props.meta.unitID) {
      case this.state.kg:
        value = 0.1;
        break;
      case this.state.st:
        value = this.props.meta.Stegom;
        break;
      case this.state.kolli:
        break;
      default:
        break;
    }
    this.setState({
      step: value,
    });
  }

  componentDidUpdate(prevProps: UpdateAmountFormProps) {
    if (this.props.meta.amount !== prevProps.meta.amount) {
      this.setState({
        tempAmount: this.props.meta.amount,
      });
    }
    if (this.props.meta.unitID !== prevProps.meta.unitID) {
      this.setState({
        tempUnit: this.props.meta.unitID,
        selectText:
          this.props.meta.unitID === 1
            ? 'Vikt'
            : this.props.meta.unitID === 2
              ? 'Antal'
              : 'Kolli',
      });
    }
    if (
      this.props.meta.productID !== prevProps.meta.productID
      && this.props.meta.unitID !== prevProps.meta.unitID
    ) {
      this.setState({
        valid: true,
        step:
          this.state.tempUnit === this.state.st
            ? this.props.meta.Stegom
            : this.state.tempUnit === this.state.kg
              ? 0.1
              : 1,
      });
    }

    if (
      this.props.meta.unitID !== prevProps.meta.unitID ||
      this.props.meta.amount !== prevProps.meta.amount
    ) {
      this.setState({ loading: false });
    }
  }

  onChangeAmount(event: ChangeEvent<HTMLInputElement>) {
    const amount = event.currentTarget.value;

    this.setState({
      tempAmount: amount,
    });

    this.props.updateProductAmount(this.props.meta.productID, amount);
  }

  onChangeUnit(selectedUnit: string) {
    const unit = parseInt(selectedUnit, 10);
    let buyingProductPieces = false;

    let textVal = '';
    let value = 0;
    switch (unit) {
      case this.state.kg:
        value = 0.1;
        textVal = 'Vikt';
        break;
      case this.state.st:
        value = this.props.meta.Stegom;
        buyingProductPieces = true;
        textVal = 'Antal';
        break;
      case this.state.kolli:
        value = 1;
        textVal = 'Kolli';
        break;
      default:
        // Unhandled
        break;
    }

    this.props.buyProductPiece(this.props.meta.productID, buyingProductPieces);

    this.setState({
      selectText: textVal,
      tempUnit: unit,
      step: value,
    });
  }

  onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    this.setState({
      hasClicked: true,
    });
  }

  onSubmit(event: FormEvent) {
    event.preventDefault();
    const index = this.state.bufferIndex + 1;

    if (this.state.stage === 0) {
      this.setState({
        stage: 1,
      });
    } else {
      const amount = this.state.tempAmount;
      const unit = parseInt(this.state.tempUnit.toString(), 10);
      const oneDecimal = new RegExp('(^\\d*(\\.|\\,)\\d{1}$)|(^\\d+$)');
      const integer = new RegExp('(^\\d+$)');

      if (!amount || parseFloat(amount) <= 0) {
        this.setState({
          valid: false,
          infoMessage: 'Du har inte matat in antal som är större än 0.',
        });
      } else if (!oneDecimal.test(amount)) {
        this.setState({
          valid: false,
          infoMessage: 'Minsta viktenheten är 0,1 kg.',
        });
      } else if (
        unit === this.state.st &&
        parseFloat(amount) % this.props.meta.Stegom > 0
      ) {
        this.setState({
          valid: false,
          infoMessage: `Denna artikel köpes om ${this.props.meta.Stegom} st.`,
        });
      } else if (
        !integer.test(amount) &&
        (unit === this.state.st || unit === this.state.kolli)
      ) {
        this.setState({
          valid: false,
          infoMessage: 'Enheten kan endast hantera heltal.',
        });
      } else {
        this.setState({
          valid: true,
          infoMessage: null,

          hasClicked: false,
          stage: 0,
          selectText:
            this.props.meta.unitID === 1
              ? 'Vikt'
              : this.props.meta.unitID === 2
                ? 'Antal'
                : 'Kolli',
        });

        const newProduct = {
          productID: this.props.meta.productID,
          amount: parseFloat(amount),
          unit,
        };
        const oldProduct = {
          productID: this.props.meta.productID,
          amount: parseFloat(this.props.meta.amount),
          unit: this.props.meta.unitID,
        };

        this.setState(
          {
            loading: true,
            bufferIndex: index,
          },
          () => {
            setTimeout(() => {
              if (this.state.bufferIndex === index) {
                this.props.updateProduct(oldProduct, newProduct);
              }
            }, 1000);
          },
        );
      }
    }
  }

  onCancel(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    const self = this;
    const { amount, unitID } = this.props.meta;

    this.setState({
      tempAmount: amount,

      hasClicked: true,
      stage: 0,
    }, () => {
      self.onChangeUnit(unitID.toString());
    });
  }

  render() {
    let dropdown;
    const amount = (
      <input
        type="number"
        onChange={this.onChangeAmount}
        className={
          `form-control col amountform${
            this.state.valid ? '' : ' is-invalid'}`
        }
        aria-label=""
        placeholder={this.state.selectText}
        step={this.state.step}
        min={0}
        value={this.state.tempAmount}
        required
      />
    );

    if (!this.props.meta.Ej_Delbar) {
      if (this.props.meta.Pris_per_styck) {
        dropdown = (
          <div className="input-group-append col px-0">
            <button
              onClick={this.onClick}
              className="btn dropdown-toggle rounded-0 border-right-0 w-100 btn-gsh"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {this.state.selectText}
            </button>
            <div className="dropdown-menu pointer">
              <button
                onClick={(e) => { e.preventDefault(); this.onChangeUnit(e.currentTarget.value); }}
                className="dropdown-item"
                type="button"
                value={this.state.kolli}
              >
                Kolli
              </button>
              <button
                onClick={(e) => { e.preventDefault(); this.onChangeUnit(e.currentTarget.value); }}
                className="dropdown-item"
                type="button"
                value={this.state.st}
              >
                Antal (st)
              </button>
            </div>
          </div>
        );
      } else {
        dropdown = (
          <div className="input-group-append col px-0">
            <button
              onClick={this.onClick}
              className="btn dropdown-toggle rounded-0 border-right-0 w-100 btn-gsh"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {this.state.selectText}
            </button>
            <div className="dropdown-menu pointer">
              <button
                onClick={(e) => { e.preventDefault(); this.onChangeUnit(e.currentTarget.value); }}
                className="dropdown-item"
                type="button"
                value={this.state.kolli}
              >
                Kolli
              </button>
              <button
                onClick={(e) => { e.preventDefault(); this.onChangeUnit(e.currentTarget.value); }}
                className="dropdown-item"
                type="button"
                value={this.state.kg}
              >
                Vikt (Kg)
              </button>
            </div>
          </div>
        );
      }
    } else {
      dropdown = (
        <div className="input-group-append col px-0">
          <button
            className="btn btn-secondary dropdown-toggle rounded-0 border-right-0 ej-delbar w-100"
            type="button"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Ej Delbar"
            aria-haspopup="true"
            aria-expanded="false"
            disabled
          >
            Kolli
          </button>
          <div className="dropdown-menu pointer">
            <button
              className="dropdown-item"
              type="button"
              value={this.state.kolli}
            >
              Kolli
            </button>
          </div>
        </div>
      );
    }

    const fields = (
      <div className="w-100">
        <div className="input-group w-100">
          {this.state.stage === 0 ? (
            <input
              type="text"
              className="form-control amountform amount"
              value={this.state.tempAmount}
              disabled
            />
          ) : (
            ''
          )}
          {this.state.stage === 0 ? dropdown : ''}
          {this.state.stage === 1 ? amount : ''}
          <div className="input-group-append col-auto px-0">
            {this.state.stage === 0 ? (
              <button
                onClick={this.onSubmit}
                rel="tooltip"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Ändra"
                className="btn icon-custom small amountform float-right btn-gsh"
                disabled={this.state.loading}
              >
                <SmallLoading title="" hasLoaded={!this.state.loading}>
                  <img
                    src="/../icon/edit.png"
                    alt="Ändra"
                    style={{ objectFit: 'scale-down' }}
                  />
                </SmallLoading>
              </button>
            ) : (
              <button
                onClick={this.onSubmit}
                style={{
                  backgroundImage: "url('/../icon/baseline-done-24px.svg')",
                }}
                rel="tooltip"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Utför ändring"
                className="btn btn-svg btn-gsh sm rounded-0"
                type="button"
              />
            )}
          </div>
          {this.state.stage === 1 ? (
            <div className="input-group-append">
              <button
                onClick={this.onCancel}
                rel="tooltip"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Avbryt"
                className="btn btn-danger amountform float-right"
              >
                <i className="font-weight-bold icon rotate">+</i>
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
        {!this.state.valid ? (
          <div
            className="alert alert-warning mt-1 mb-0 w-100"
            style={{ fontSize: '0.7rem' }}
            role="alert"
          >
            {this.state.infoMessage}
          </div>
        ) : (
          ''
        )}
      </div>
    );

    return fields;
  }
}

export default UpdateAmountForm;