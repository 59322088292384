import React, { Component, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import axios from '../bin/axios_gsh';
import Table from './Table';

interface Product {
  productID: number;
  unitID: number;
  Nummer: number;
  Benämning: string;
  amount: number;
  pris: number;
}
interface ShortProduct {
  productID: number;
  Benämning: string;
  amount: number;
  pris: number;
}

interface State {
  totalPrice: number;
  header: string[];
  products: ShortProduct[];
  showCart: boolean;
  skipDataColumns: number[];
  unitNames: string[];
}

interface Props {
  cart: Product[];
  showCart: boolean;
  updateCart: () => void;
}

class SideCart extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      totalPrice: 0,
      header: ['productID', 'Benämning', 'Enhet', 'SEK'],
      products: [],
      showCart: false,
      skipDataColumns: [0],
      unitNames: [
        '',
        'Kg',
        'Antal',
        'Kolli',
      ],
    };

    this.openCart = this.openCart.bind(this);
    this.makeSmallCartArr = this.makeSmallCartArr.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  openCart() {
    this.setState((prevState) => ({
      showCart: !prevState.showCart,
    }));
  }

  componentDidMount() {
    this.makeSmallCartArr();
  }

  componentDidUpdate(prevProps: Props) {
    if (JSON.stringify(prevProps.cart) !== JSON.stringify(this.props.cart)) {
      this.makeSmallCartArr();
    }

    if (this.props.showCart !== prevProps.showCart) {
      this.setState({
        showCart: true,
      });
    }
  }

  makeSmallCartArr() {
    const arr = [...this.props.cart];
    const products: ShortProduct[] = [];
    for (let i = 0; i < arr.length; i++) {
      const product: ShortProduct = {
        productID: arr[i].productID,
        Benämning: arr[i].Benämning,
        amount: arr[i].amount,
        pris: arr[i].pris,
      };

      products.push(product);
    }
    this.setState({
      products,
    });
  }

  handleDelete(event: React.MouseEvent, product: { productID: number; unit: number }) {
    event.preventDefault();
    const self = this;

    axios.post('cart/remove', product).then((response) => {
      // Before Gustav visa användaren fel
      self.props.updateCart();
    });
  }

  getItemNr() {    
  }

  render() {
    let cart: ReactElement<any, any> | null = null;

    if (this.state.showCart) {
      cart = (
        <div>
          <div
            className="card shadow-sm mb-5 rounded "
            style={{
              position: 'fixed', right: 0, height: '87%', backgroundColor: 'white', zIndex: 1000, width: '20em',
            }}
          >
            <div className="card-header">
              Varukorg
              <button type="button" className="close" aria-label="Close" onClick={this.openCart}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div style={{ overflowY: 'auto', height: '89%', overflowX: 'hidden' }}>
              {this.state.products !== null && this.state.products.length > 0
                ? (
                  <Table
                    style={{
                      tableLayout: 'fixed', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                    }}
                    clickFunction={this.getItemNr}
                    countRows={false}
                    headers={this.state.header}
                    skipDataColumns={this.state.skipDataColumns}
                    data={this.state.products}
                    identifierIndex={0}
                  />
                )
                : null}
            </div>

            <div style={{
              position: 'fixed', bottom: 0, right: 0, width: '20em',
            }}
            >
              <Link to="/order">
                <div className="btn btn-lg btn-gsh col-sm-12 rounded-0">
                  Gå Till Ordersida
                </div>
              </Link>
            </div>
          </div>
        </div>
      );
    } else {
      cart = <div
              onClick={this.openCart}
              className="show-cart rounded-left shadow-sm mb-5 pointer"
              style={{
                height: '50px',
                backgroundColor: '#28a745',
                zIndex: 1000,
                marginTop: '20px',
                backgroundImage: 'url("/../icon/shopping_cart-white-24px.svg")',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            />;
    }
    return (
      <div style={{ position: 'absolute', right: 0 }}>
        {cart}
      </div>
    );
  }
}

export default SideCart;