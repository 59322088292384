import React, { ReactNode, MouseEvent } from 'react';

interface FavButtonProps {
  isFav: boolean;
  makeFav: (event: MouseEvent<HTMLButtonElement>) => void;
}

class FavButton extends React.Component<FavButtonProps> {
  render(): ReactNode {
    const img = '/icon/fav.png';

    return (
      <button
        className={this.props.isFav ? 'btn btn-gsh icon-custom-round' : 'btn btn-outline-gsh icon-custom-round'}
        onClick={this.props.makeFav}
        title={this.props.isFav ? 'Ta bort från favoriter' : 'Lägg till i favoriter'}
      >
        <img src={img} alt="Favorit" />
      </button>
    );
  }
}

export default FavButton;
