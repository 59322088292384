import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import LazyLoad from 'react-lazy-load';
import { format } from 'date-fns';
import axios from './bin/axios_gsh';
import ProductSearchBar from './components/SearchBarProduct';
import DiscountedProduct from './DiscountedProduct';
import FavButton from './components/FavButton';
import AmountForm from './components/AmountForm';
import Table from './components/Table';
import Pagination from './components/Pagination';
import Modal from './components/Modal.tsx';
import ProductDetailModal  from './components/ProductDetailModal';

import 'flag-icon-css/css/flag-icon.css';

class Stock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAssortment: false,
      showSpecialList: false,

      products: [],
      discountedProducts: [],
      data: [],
      headers: ['ID', 'Artikel', '', '', 'Avtalsartikel', 'Jmfr pris','Kolli stl.', 'Pris (SEK)', ''],
      columnSizing: ['1px', '100px', null, '1px', '110px','110px', '88px', '110px', '180px'],
      skipDataColumns: [],
      items: 0,
      page: 0,
      defaultPerPage: 50,

      showImages: true,
      searchResults: false,
      refresh: false,

      categories: [],
      title: null,

      discountedProductsHasLoaded: false,
      hasLoaded: false,
      categoriesHasLoaded: false,
      reload: false,

      showErrorModal: false,
      error: null,
      config: null,

      modalIsOpen: false,
      selectedProduct: null
    };

    this.loadData = this.loadData.bind(this);
    this.setSearchResult = this.setSearchResult.bind(this);
    this.beginSearch = this.beginSearch.bind(this);
    this.toggleImages = this.toggleImages.bind(this);
    // this.setCategory                = this.setCategory.bind(this);
    // this.setFilter                  = this.setFilter.bind(this);
    this.setPerPage = this.setPerPage.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.updateFav = this.updateFav.bind(this);
    this.setModal = this.setModal.bind(this);
    this.buyProductPiece = this.buyProductPiece.bind(this);
    this.updateProductAmount = this.updateProductAmount.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
  }

  componentWillUnmount() {}

  componentDidMount() {
    const self = this;
    (axios.get('user/company').then((response) => {
      response = response.data;

      self.setState({
        showAssortment: (!response.EnbartAvtalatSortiment && response.ID_Sortimentslistor > 0),
        showSpecialList: response.EnbartAvtalatSortiment,
      }, () => {
        self.updateSkipDataColumns();
      });
    }));

    (axios.get('product/categories').then((response) => {
      response = response.data;
      const categories = [];
      response.forEach((element) => {
        const temp = {
          id: element.ID,
          name: element.Namn,
          isLoading: false,
        };
        categories.push(temp);
      });

      let index = 0;
      for (let i = 0; i < categories.length; i++) {
        const cat = categories[i];
        if (cat.id === self.props.categoryID) {
          index = i;
          break;
        }
      }

      if (index === 0) {
        index = 1;
        // Category doesn't exist should redirect
      }

      let { title } = self.state;
      if (self.props.view === 'category') {
        title = categories[index].name;
      }

      self.setState({
        categories,
        title,
        searchResults: false,
        hasLoaded: false,
        categoriesHasLoaded: true,
      }, () => {
        self.loadData();
      });
    }));

    (axios.get('config/getConfig').then(({ data }) => {
      this.setState({
        config: data,
      });
    }));
  }

  getTitle() {
    let title = null;
    switch (this.props.view) {
      case 'campaign':
        title = 'Kampanjvaror';
        break;
      case 'all':
        title = 'Alla artiklar';
        break;
      case 'agreement':
        title = 'Avtalsartiklar';
        break;
      default:
        title = this.state.title;
        break;
    }
    return title;
  }

  componentDidUpdate(prevProps) {
    if (this.state.categoriesHasLoaded
        && (prevProps.view !== this.props.view || prevProps.categoryID !== this.props.categoryID)) {
      let title = null;
      title = this.getTitle();

      this.setState({
        title,

        page: 0,
        searchResults: false,
        hasLoaded: false,
      }, () => {
        this.loadData();
      });
    }

    if (JSON.stringify(prevProps.filter) !== JSON.stringify(this.props.filter)) {
      const refresh = this.state.searchResults;

      this.setState({
        page: 0,
        hasLoaded: false,
        refresh,
      }, () => {
        this.loadData();
      });
    }

    if (prevProps.perPage !== this.props.perPage && [50, 100, 200].some((p) => p == this.props.perPage)) {
      this.loadData();
    }
  }

  updateFav(productID, isFav) {
    const self = this;
    if (isFav > 0) {
      (axios.delete('product/user/favorite/row/delete', { data: { ProduktID: productID } }).then((response) => {
        self.loadData();
      }));
    } else {
      (axios.post('product/user/favorite/row/add', { ProduktID: productID }).then((response) => {
        self.loadData();
      }));
    }
  }

  getDiscountTag(pris, originalPris) {
    if (pris && originalPris && pris != originalPris) { return `-${(100 - ((pris.replace(',', '.') * 100) / originalPris.replace(',', '.'))).toFixed().replace('.', ',')}%`; }
    return '';
  }

  getCampaignProducts() {
    const self = this;

    (axios.get('product/discount').then((response) => {
      response = response.data;
      const arr = [];

      if (response) {
        response.forEach((element) => {
          const productInfo = {
            productID: element.ID_Produkt,
            itemnr: element.Nummer,
            weightPerPackage: element.Förpackningsstorlek,
            sizeOptions: element.Stegom,
            name: element.Benämning,
            fav: element.Favorit,
            origin: element.Landskod,
            priceExits: element.PrisFinns,
            priceRelText: element.PrisText,
            weightPerUnit: element.Vikt_per_enhet,
            priceForOne: (element.Pris !== null) ? (element.Pris).toFixed(2).replace('.', ',') : null,
            priceForOneOriginal: (element.OriginalPris !== null) ? (element.OriginalPris).toFixed(2).replace('.', ',') : null,
            notDividable: element.Ej_delbar,
            vat: element.Moms,
            canBuyPieces: element.Pris_per_styck,
            URL_img: element.URL_Bild,
            eko: element.eko,
            krav: element.krav,
            ursprungsland: element.Ursprungsland,
            jmfr_pris: element.jmfr_pris,
            promotionalItem: true,
            senaste_Leveransdatum: element.Senaste_Leveransdatum,
            amount: 0,
            buyingPieces: false
          };

          arr.push(productInfo);
        });
      }

      self.setState({
        items: arr.length,
      });
      self.setState({
        discountedProducts: arr,
        discountedProductsHasLoaded: true,
      });

      self.setProducts(arr);
    }));
  }

  loadData() {
    const self = this;

    if (this.props.view === 'campaign') {
      this.getCampaignProducts();
    } else if (!this.state.searchResults) {
      const showPromotionalItems = this.state.page == 0;
      if (!showPromotionalItems) {
        self.props.setPerPage(this.state.defaultPerPage);
      }
      const conditions = {
        perPage: this.state.defaultPerPage,
        offset: this.state.defaultPerPage * this.state.page,
        view: this.props.view,
        // Express can't sanitize if int or null
        categoryID: (this.props.categoryID) ? parseInt(this.props.categoryID, 10) : 0,
        filter: this.props.filter,
      };
      let index = 0;
      for (let i = 0; i < this.state.categories.length; i++) {
        const cat = this.state.categories[i];
        if (cat.id === conditions.categoryID) {
          index = i;
          break;
        }
      }
      let title = this.getTitle();
      if (self.props.view === 'category') {
        title = self.state.categories[index].name;
      }

      self.setState({
        hasLoaded: false,
        title,
      }, async () => {
        const products = [];

        if (showPromotionalItems && this.props.view === 'all') { // show promotional itens on first page
          (await axios.get('product/discount').then((response) => {
            response = response.data;
            if (response) {
              response.forEach((element) => {
                const productInfo = {
                  productID: element.ID_Produkt,
                  itemnr: element.Nummer,
                  weightPerPackage: element.Förpackningsstorlek,
                  sizeOptions: element.Stegom,
                  name: element.Benämning,
                  fav: element.Favorit,
                  origin: element.Landskod,
                  priceExits: element.PrisFinns,
                  priceRelText: element.PrisText,
                  weightPerUnit: element.Vikt_per_enhet,
                  priceForOne: (element.Pris !== null) ? (element.Pris).toFixed(2).replace('.', ',') : null,
                  priceForOneOriginal: (element.OriginalPris !== null) ? (element.OriginalPris).toFixed(2).replace('.', ',') : null,
                  notDividable: element.Ej_delbar,
                  vat: element.Moms,
                  canBuyPieces: element.Pris_per_styck,
                  URL_img: element.URL_Bild,
                  eko: element.eko,
                  krav: element.krav,
                  ursprungsland: element.Ursprungsland,
                  jmfr_pris: element.jmfr_pris,
                  promotionalItem: true,
                  senaste_Leveransdatum: element.Senaste_Leveransdatum,
                  amount: 0,
                  buyingPieces: false
                };

                products.push(productInfo);
              });
            }
          }));
        }
        (await axios.post('product/filter', conditions).then((response) => {
          response = response.data;
          let items = 0;
          if (Array.isArray(response) && response.length > 0) {
            items = response[0].TotaltAntalRader;
            response.forEach((element) => {
              let found; let
                product = null;

              if (self.state.discountedProducts.length > 0) {
                found = self.state.discountedProducts.find(({ productID }) => productID === element.ID_Produkt);
              }

              if (typeof found !== null && typeof found !== 'undefined') {
                product = {
                  ...found,
                  amount: 0,
                  buyingPieces: false
                }
              } else {
                product = {
                  productID: element.ID_Produkt,
                  itemnr: element.Nummer,
                  weightPerPackage: element.Förpackningsstorlek,
                  sizeOptions: element.Stegom,
                  name: element.Benämning,
                  fav: element.Favorit,
                  origin: element.Landskod,
                  priceExits: element.PrisFinns,
                  priceRelText: element.PrisText,
                  categoryID: element.ID_Kategori,
                  weightPerUnit: element.Vikt_per_enhet,
                  priceForOne: (element.Pris !== null) ? (element.Pris).toFixed(2).replace('.', ',') : null,
                  priceForOneOriginal: (element.Pris !== null) ? (element.Pris).toFixed(2).replace('.', ',') : null,
                  notDividable: element.Ej_delbar,
                  selection: element.Avtalsartikel,
                  vat: element.Moms,
                  canBuyPieces: element.Pris_per_styck,
                  URL_img: element.URL_Bild,
                  eko: element.eko,
                  krav: element.krav,
                  ursprungsland: element.Ursprungsland,
                  jmfr_pris: element.jmfr_pris,
                  promotinalItem: false,
                  amount: 0,
                  buyingPieces: false
                };
              }

              products.push(product);
            });
          }

          self.setState({
            items
          });

          self.setProducts(products);
        }));

        if (showPromotionalItems) {
          self.props.setPerPage(products.length);
        }
      });
    } else {
      self.setState({
        refresh: true,
      });
    }
  }

  setProducts(products) {
    const self = this;
    const offset = new Array(this.state.page * this.props.perPage);

    this.setState({
      products: [
        ...offset,
        ...products,
      ],
    }, () => {
      self.updateData();
    });
  }

  addProduct(product) {
    const self = this;

    (axios.post('cart/add', product).then((response) => {
      response = response.data;
      if (response.success && response.error) {
        const error = response.error[0];

        self.setState({
          showInfoBox: true,
          infoMessage: `${error.title}: ${error.description}`,
        });
      }
      if (!response.success) {
        const error = response.error[0];
        self.setState({
          showErrorModal: true,
          error,
        });
      }

      self.props.updateCart();
    }));
  }

  buyProductPiece(productId, isBuying){
    let productList = this.state.products;
    let product = productList.find((p) => p.productID === productId);
    if(product)
        product.buyingPieces = isBuying;

    this.setState({
        products: productList,
    }, () => {
        this.updateData();
    });
  }

  updateProductAmount(productId, amount) {
    let updatedProducts = this.state.products;
    let productToUpdate = updatedProducts.find((p) => p.productID === productId);
    productToUpdate.amount = amount;
    this.setState({
        products: updatedProducts
    }, () => {
        this.updateData();
    })
  }

  setModal(value) {
    this.setState({
      showErrorModal: value,
    });
  }

  informAboutAddedProduct(alreadyExists, name, itemnr) {
    const message = (
      <div>
        Varan
        <b>{name}</b>
        (
        {itemnr}
        ) finns redan i din varukorg.
        <br />
        {' '}
        Lags till på existerande artikelrad
      </div>
    );
    if (alreadyExists) {
      this.setState({
        showInfoBox: true,
        infoMessage: message,
      });
    } else {
      this.setState({
        showInfoBox: false,
        infoMessage: '',
      });
    }
  }

  setSearchResult(data) {
    this.setState({
      products: data,
      searchResults: true,
      refresh: false,
    }, () => {
      this.updateData();
    });
  }

  beginSearch() {
    this.setState({
      title: 'Sökresultat',
      hasLoaded: false,
      page: 0,
    });
  }

  updateData() {
    const { products } = this.state;

    const data = [];
    for (let i = 0; i < products.length; i++) {
      let product;

      if (this.state.discountedProducts.length > 0) {
        let discountedProduct = this.state.discountedProducts.find( ({ productID }) => 
            products[i] && productID === products[i].productID
        )
        if(discountedProduct) {
            discountedProduct.amount = products[i].amount;
            discountedProduct.buyingPieces = products[i].buyingPieces
        }
        product = discountedProduct;
    }

      if (typeof product === null || typeof product === 'undefined') {
        product = products[i];
      }

      const newProduct = {
        productID: null,
        img: null,
        item: null,
        favorite: null,
        special: null,
        jmfr_pris: null,
        unitsize: null,
        price: null,
        // vat: null,
        // notDividable: null,
        // priceExists: null,
        unit: null,
      };

      if (product !== undefined) {
        const unitsize = product.weightPerPackage;

        const price = ((product.priceExits)
          ? (product.canBuyPieces)
            ? product.priceForOne // + ' kr/st'
            : product.priceForOne // +' kr/kg'
          : '-');

        newProduct.productID = product.productID;
        newProduct.img = ((product.URL_img !== null && product.URL_img.length > 0))
          ? (
            <div className="price-marker-tag-box">
              <LazyLoad width="6rem" height="6rem">
              <img className="lazy" onClick={() => this.selectProduct(product)} src={process.env.REACT_APP_IMG_SRC + product.URL_img} alt="Artikelbild" style={{ width: '6rem', height: '6rem', objectFit: 'scale-down',cursor: 'pointer' }} />
              </LazyLoad>
              <div className="price-marker-tag">{this.getDiscountTag(product.priceForOne, product.priceForOneOriginal)}</div>
            </div>
          )
          : <img className="lazy" onClick={() => this.selectProduct(product)} src={process.env.REACT_APP_IMG_NOTFOUND_PATH} alt="Artikelbild" style={{ width: '6rem', height: '6rem', objectFit: 'scale-down',cursor: 'pointer' }} />;

        newProduct.item = (
          <div className="">
            <Link className="text-break font-weight-bold text-dark" to='#' onClick={() => this.selectProduct(product)}>{product.name}</Link>
            <br />
            {
                            (product.origin && product.origin !== 'ÖV')
                              ? <span alt={product.origin} className={`icon-flag flag-icon flag-icon-${(`${product.origin}`).toLowerCase()}`} rel="tooltip" data-toggle="tooltip" data-placement="bottom" title={product.ursprungsland} />
                              : <span alt={product.origin} className="icon-flag flag-icon flag-icon-öv" rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="Övriga länder">öv</span>
                        }
            {
                            (product.krav)
                              ? <img alt="KRAV-märkt" className="icon-flag" src="/icon/krav.jpg" rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="KRAV-märkt" />
                              : ''
                        }
            {
                            (product.eko)
                              ? <img alt="Ekologisk" className="icon-flag" src="/icon/eko.png" rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="Ekologisk" />
                              : ''
                        }
            {' | '}
            <Link className="text-break text-muted" to='#' onClick={() => this.selectProduct(product)}>{product.itemnr}</Link>
            <br />
            { product.senaste_Leveransdatum
                            && (
                            <span className="small font-italic">
                              Senaste leveransdag:
                              {format(new Date(product.senaste_Leveransdatum), 'yyyy-MM-dd')}
                            </span>
                            )}
            {
              this.state.config.canBuyPieces && product.buyingPieces &&
              <>  
                  <br />
                  <span className="small font-italic">
                      Priset beräknas per kg, 1 styck väger {product.weightPerUnit}. Beställt är{" "}
                      {(product.amount ? product.weightPerUnit * product.amount  : 0).toFixed(3)} kg.
                  </span>
              </>
            }
          </div>
        );

        newProduct.favorite = <FavButton isFav={product.fav} makeFav={(e) => { e.preventDefault(); this.updateFav(product.productID, product.fav); }} />;
        newProduct.special = (product.selection)?
          <>
              <div className="w-100 d-flex justify-content-center align-items-center">
                  <i className="icon-custom-round icon-accept">
                  </i>                        
              </div>
              <div className="d-flex justify-content-center align-items-center font-italic mt-2" style={{fontSize: "14px"}}>
                  <span>Avtalad</span>
              </div>
          </>                    
          : 
          <div className="w-100 d-flex justify-content-center align-items-center">
              <i className="icon-custom-round icon-deny">
              
              </i>
          </div>;

        newProduct.unitsize = unitsize + ((product.canBuyPieces) ? 'st' : 'kg');
        newProduct.jmfr_pris = <div className="price-marker">{product.jmfr_pris}</div>;
        newProduct.price = <div className={"price-marker " + (product.promotionalItem ? 'price-marker-promotional-items' : '')}>{price} kr</div>;


        newProduct.unit = (product.priceExits)
          ? (
            <div className="float-right w-100">
              <AmountForm 
                        productID={product.productID} 
                        canBuyPieces={product.canBuyPieces} 
                        buyProductPiece={this.buyProductPiece} 
                        updateProductAmount={this.updateProductAmount}
                        notDividable={product.notDividable} 
                        unitsize={unitsize} 
                        sizeOptions={product.sizeOptions} 
                        addProduct={this.addProduct}></AmountForm>            
            </div>
          )
          : product.priceRelText;
      }
      data.push(newProduct);
    }

    this.setState({
      data,
      hasLoaded: true,
    }, () => {
    });
  }

  toggleImages() {
    this.setState({
      showImages: !this.state.showImages,
    }, () => {
      this.updateSkipDataColumns();
    });
  }

  updateSkipDataColumns() {
    const skipDataColumns = [0];
    const headers = [...this.state.headers];
    if (!this.state.showImages) {
      skipDataColumns.push(1);
      if (headers[1] === 'Artikel') {
        headers[2] = 'Artikel';
      }
    } else {
      headers[2] = '';
    }

    this.setState({
      skipDataColumns,
      headers,
    });
  }

  setPerPage(e) {
    e.preventDefault();
    const target = e.currentTarget;

    if (target.value !== null) {
      const value = parseInt(target.value, 10);

      this.setState({
        page: 0,
        defaultPerPage: value,
      });

      this.props.setPerPage(value);
    }
  }

  tableClick(identifier) {}

  closeModal() {
    this.setState({
      selectedProduct: null,
      modalIsOpen: false,
    })
    document.body.style.overflow = 'auto';
  }

  selectProduct(product){
    this.setState({
      selectedProduct: product,
      modalIsOpen: true,
    })
    document.body.style.overflow = 'hidden';
  }
  
  render() {
    const self = this;

    let errorDiv;
    if (this.state.onlySpecialList && (this.state.products === null || this.state.products.length === 0)) {
      errorDiv = (
        <div className="alert alert-danger" role="alert">
          {this.state.errorMessage}
        </div>
      );
    }
    let infoDiv;
    if (this.state.showInfoBox) {
      infoDiv = (
        <div className="alert alert-primary" role="alert">
          {this.state.infoMessage}
        </div>
      );
    }

    const setPage = function (page) {
      self.setState({
        page,
      }, () => {
        self.loadData();
      });
    };

    let pages = 0;
    const items = (this.state.searchResults) ? this.state.data.length : this.state.items;

    if ((items / this.props.perPage) % 1 !== 0) { pages = Math.floor(items / this.props.perPage) + 1; } else {
      pages = Math.floor(items / this.props.perPage);
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <nav className="navbar navbar-light d-block d-xl-none float-left pl-0">
              <button className="navbar-toggler bg-white" type="button" data-toggle="collapse" data-target="#sidebar" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
              </button>
            </nav>
            {this.state.selectedProduct && this.state.modalIsOpen &&
              <ProductDetailModal
                    productName={this.state.selectedProduct?.name}
                    updateCart={this.props.updateCart}
                    nummer={this.state.selectedProduct?.productID}
                    modalIsOpen={this.state.modalIsOpen}
                    closeModal={this.closeModal}
                    buyProductPiece={this.buyProductPiece} 
                    updateProductAmount={this.updateProductAmount}
                  />
            }
            <h1>{this.state.title}</h1>
            {errorDiv}
            {infoDiv}
            {this.state.showErrorModal && (
            <Modal outsideClickCloses={false} setModal={(value) => this.setModal(value)}>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h2>{this.state.error.title}</h2>
                    <p>{this.state.error.description}</p>
                  </div>
                </div>
              </div>
            </Modal>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="">
              <ProductSearchBar refresh={this.state.refresh} onSearch={this.setSearchResult} beginSearch={this.beginSearch} filter={this.props.filter}>
                <button className="btn btn-outline-primary dropdown-toggle rounded-0 border-right-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{this.state.defaultPerPage}</button>
                <div className="dropdown-menu pointer">
                  <button className="dropdown-item" type="button" onClick={this.setPerPage} value={50}>50</button>
                  <button className="dropdown-item" type="button" onClick={this.setPerPage} value={100}>100</button>
                  <button className="dropdown-item" type="button" onClick={this.setPerPage} value={200}>200</button>
                </div>
              </ProductSearchBar>
            </div>
            <img src="/icon/fav.png" className="d-none" alt="Favorit" />
            <img src="/icon/baseline-done-24px.svg" className="d-none" alt="done" />
          </div>
          <div className="col-auto">
            {
                            ((this.state.showImages)
                              ? <button style={{ backgroundSize: '38px 38px' }} rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="" onClick={this.toggleImages} className="btn btn-svg icon lg  btn-lg float-left show-image toggle btn-primary" />
                              : <button style={{ backgroundSize: '38px 38px' }} rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="" onClick={this.toggleImages} className="btn btn-svg icon lg  btn-lg float-left show-image btn-outline-primary" />
                            )
                        }
          </div>
        </div>
        {
                    ((this.state.data.length > 0 && this.state.hasLoaded) || !this.state.hasLoaded)
                      ? (
                        <div>
                          {
                                (this.state.data.length > 0 && this.state.hasLoaded)
                                  ? (
                                    <div className="row">
                                      <div className="col-md-12">
                                        <Pagination current={this.state.page} setPage={setPage} pages={pages} />
                                      </div>
                                    </div>
                                  )
                                  : ''
                            }
                          <div className="row">
                            <div className="col-md-12">
                              <div className="border rounded">
                                <Table className="order-table" clickFunction={this.tableClick} columnSizing={this.state.columnSizing} countRows={false} hasLoaded={this.state.hasLoaded} page={this.state.page} perPage={this.props.perPage} headers={this.state.headers} skipDataColumns={this.state.skipDataColumns} data={this.state.data} identifierIndex={0} />
                              </div>
                            </div>
                          </div>
                          {
                                (this.state.data.length > 0 && this.state.hasLoaded)

                                  ? (
                                    <div className="row">
                                      <div className="col-md-12">
                                        <Pagination current={this.state.page} setPage={setPage} pages={pages} anchor="" />
                                      </div>
                                    </div>
                                  )
                                  : ''
                            }
                        </div>
                      )
                      : (
                        <div>
                          <hr />
                          <h3>Denna kategori är tom.</h3>
                        </div>
                      )
}
      </div>
    );
  }
}

export default withRouter(Stock);
