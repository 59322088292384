import React from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';
import axios from './bin/axios_gsh';
import Table from './components/Table';
import AmountForm from './components/AmountForm';
import SearchBarFavorite from './components/SearchBarFavorite';
import Modal from './components/Modal.tsx';

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userid: null,
      units: [],
      identifierIndex: 0,
      showImages: true,
      showAssortment: false,

      headers: ['ID', 'Artikel', '', '', 'Kolli stl.', 'Pris (SEK)', '', ''],
      columnSizing: ['1px', '100px', null, '1px', '88px', '110px', '200px', '1px'],
      data: [
      ],
      skipDataColumns: [0, 3],

      changedProduct: [],
      searchProducts: [],
      infoMessage: '',
      showInfoMessage: false,
      hasLoaded: false,

      showErrorModal: false,
      error: null,
    };
    this.loadData = this.loadData.bind(this);
    this.loadProducts = this.loadProducts.bind(this);
    this.toggleImages = this.toggleImages.bind(this);
    this.reload = this.reload.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.addProductToFavorite = this.addProductToFavorite.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.saveFavorites = this.saveFavorites.bind(this);
    this.informAboutAddedProduct = this.informAboutAddedProduct.bind(this);
    this.setModal = this.setModal.bind(this);
  }

  change(templateRow, change) {
    const obj = {
      object: templateRow,
      change,
    };
    const pos = this.isChanged(templateRow.productID);
    const arr = [...this.state.changedProduct];
    if (pos === -1) {
      arr.push(obj);
    } else {
      arr[pos] = obj;
    }

    this.setState({
      changedProduct: arr,
    }, () => {
      this.loadData();
    });
  }

  isChanged(id) {
    // Returns index position if found, else -1
    for (let i = 0; i < this.state.changedProduct.length; i++) {
      const obj = this.state.changedProduct[i].object;
      if (obj.productID === id) {
        return i;
      }
    }
    return -1;
  }

  reload() {
    this.setState({
      products: [],
      data: [],
      hasLoaded: false,
      changedProduct: [],
    }, () => { this.loadProducts(); });
  }

  addProductToFavorite(event, product) {
    let notFound = true;
    this.state.data.forEach((row) => {
      if (row.productID === product.ProduktID) {
        notFound = false;
      }
    });

    this.state.changedProduct.forEach((row) => {
      if (row.object.productID === product.ProduktID) {
        notFound = false;
      }
    });

    if (notFound) {
      // Need to assign start value
      const searchProduct = {
        productID: product.ProduktID,
        itemnr: product.Nummer,
        weightPerPackage: product.Förpackningsstorlek,
        sizeOptions: product.Stegom,
        name: product.Benämning,
        fav: 1,
        origin: product.Landskod,
        priceExits: product.PrisFinns,
        priceRelText: product.PrisText,
        categoryID: product.ID_Kategori,
        weightPerUnit: product.Vikt_per_enhet,
        priceForOne: (product.Pris !== null) ? (product.Pris).toFixed(2).replace('.', ',') : null,
        notDividable: product.Ej_Delbar,
        selection: product.IngårISortiment,
        vat: product.Moms,
        canBuyPieces: product.Pris_per_styck,
        URL_img: product.URL_Bild,
        eko: product.eko,
        krav: product.krav,
        ursprungsland: product.Ursprungsland,
        aktiv: product.Aktiv,
      };
      this.change(searchProduct, 'add');
    }
  }

  setModal(value) {
    this.setState({
      showErrorModal: value,
    });
  }

  addProduct(product) {
    const self = this;
    axios.post('cart/add', product).then((response) => {
      response = response.data;
      if (response.success && response.error) {
        const error = response.error[0];

        self.setState({
          showErrorModal: true,
          error: {
            title: error.title,
            description: error.description,
          },
        });
      }
      if (!response.success) {
        const error = response.error[0];
        self.setState({
          showErrorModal: true,
          error,
        });
      }

      self.props.updateCart();
    });
  }

  deleteProduct(event) {
    this.change(this.state.data[event.currentTarget.name], 'delete');
  }

  updateSkipDataColumns() {
    const skipDataColumns = [0];
    const headers = [...this.state.headers];
    if (!this.state.showImages) {
      skipDataColumns.push(1);
      if (headers[1] === 'Artikel') {
        headers[2] = 'Artikel';
      }
    } else {
      headers[2] = '';
    }

    this.setState({
      skipDataColumns,
      headers,
    });
  }

  loadProducts() {
    const self = this;
    axios.get('product/user/favorite').then((response) => {
      response = response.data;
      const formatedResposne = [];

      if (response.length > 0) {
        response.forEach((element) => {
          const product = {
            productID: element.ID_Produkt,
            itemnr: element.Nummer,
            weightPerPackage: element.Förpackningsstorlek,
            sizeOptions: element.Stegom,
            name: element.Benämning,
            fav: 1,
            origin: element.Landskod,
            priceExits: element.PrisFinns,
            priceRelText: element.PrisText,
            categoryID: element.ID_Kategori,
            weightPerUnit: element.Vikt_per_enhet,
            priceForOne: (element.Pris !== null) ? (element.Pris).toFixed(2).replace('.', ',') : null,
            notDividable: element.Ej_Delbar,
            selection: element.IngårISortiment,
            vat: element.Moms,
            canBuyPieces: element.Pris_per_styck,
            URL_img: element.URL_Bild,
            eko: element.eko,
            krav: element.krav,
            ursprungsland: element.Ursprungsland,
            aktiv: element.Aktiv,
          };

          formatedResposne.push(product);
        });
      }

      self.setState({
        products: formatedResposne,
      }, () => {
        self.loadData();
      });
    });
  }

  loadData() {
    const self = this;
    const relevantProducts = [];
    let i = 0;
    let inactive = false;

    const add = [];
    self.state.changedProduct.forEach((product) => {
      if (product.change === 'add') {
        product.object.new = true;
        add.push(product.object);
      }
    });
    const products = add.concat(this.state.products);

    products.forEach((product) => {
      let skip = false;

      self.state.changedProduct.forEach((product2) => {
        if (skip === false && product2.change === 'delete' && product2.object.productID === product.productID) {
          skip = true;
        }
      });

      if (!skip) {
        const newProduct = {
          productID: null,
          img: null,
          item: null,
          Inactive: null,
          unitsize: null,
          price: null,
          unit: null,
          delete: null,
        };

        const unitsize = product.weightPerPackage;
        const price = ((product.priceExits)
          ? (product.canBuyPieces)
            ? product.priceForOne // + ' kr/st'
            : product.priceForOne // +' kr/kg'
          : '-');

        newProduct.productID = product.productID;

        newProduct.img = (product.URL_img !== null && product.URL_img !== '' && product.URL_img !== '')
          ? (
            <LazyLoad width="6rem" height="6rem">
              <img className="lazy" src={process.env.REACT_APP_IMG_SRC + product.URL_img} alt="Artikelbild" style={{ width: '6rem', height: '6rem', objectFit: 'scale-down' }} />
            </LazyLoad>
          )
          : <div style={{ width: '6rem', height: '6rem' }} />;

        newProduct.item = (
          <div className="">
            <Link className="text-break font-weight-bold text-dark" to={`/product/${product.productID}`}>{product.name}</Link>
            <br />
            {
                            (product.origin && product.origin !== 'ÖV')
                              ? <span alt={product.origin} className={`icon-flag flag-icon flag-icon-${(`${product.origin}`).toLowerCase()}`} rel="tooltip" data-toggle="tooltip" data-placement="bottom" title={product.ursprungsland} />
                              : <span alt={product.origin} className="icon-flag flag-icon flag-icon-öv" rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="Övriga länder">öv</span>
                        }
            {
                            (product.krav)
                              ? <img alt="KRAV-märkt" className="icon-flag" src="/icon/krav.jpg" rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="KRAV-märkt" />
                              : ''
                        }
            {
                            (product.eko)
                              ? <img alt="Ekologisk" className="icon-flag" src="/icon/eko.png" rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="Ekologisk" />
                              : ''
                        }
            {' | '}
            <Link className="text-break text-muted" to={`/product/${product.productID}`}>{product.itemnr}</Link>
          </div>
        );

        newProduct.unitsize = unitsize + ((product.canBuyPieces) ? 'st' : 'kg');
        newProduct.price = price;
        newProduct.Inactive = !product.aktiv;
        if (newProduct.Inactive) {
          inactive = true;
        }
        newProduct.unit = ((newProduct.Inactive) ? '' : (product.priceExits) ? (product.new) ? 'Spara först'
          : (
            <div className="float-right w-100">
              <AmountForm productID={product.productID} canBuyPieces={product.canBuyPieces} notDividable={product.notDividable} unitsize={unitsize} sizeOptions={product.sizeOptions} addProduct={this.addProduct} />
            </div>
          )
          : product.priceRelText);

        newProduct.delete = (
          <div>
            <button onClick={self.deleteProduct} type="button" className="btn btn-danger float-right icon-custom small" name={i}>
              <img src="/../icon/trash2.0.png" alt="Ta bort" />
            </button>
          </div>
        );
        relevantProducts.push(newProduct);
        i++;
      }
    });
    let intro = '';
    if (inactive) {
      intro = 'Det finns artiklar som har gått ut ur sortimentet, dessa listas nedan med gul markering och kan inte läggas till i varukorgen.';
    }

    self.setState({
      data: relevantProducts,
      hasLoaded: true,
      intro,
    });
  }

  saveFavorites() {
    const self = this;

    self.state.changedProduct.forEach((p) => {
      if (p.change === 'delete' && p.object.productID) {
        axios.delete('product/user/favorite/row/delete', { data: { ProduktID: p.object.productID } }).then((response) => {
          self.reload();
        });
      }
    });
  }

  informAboutAddedProduct(exists, name) {

  }

  toggleImages() {
    this.setState({
      showImages: !this.state.showImages,
    }, () => {
      this.updateSkipDataColumns();
    });
  }

  componentDidMount() {
    const self = this;
    self.updateSkipDataColumns();

    axios.get('product/units').then((units) => {
      units = units.data;

      self.setState({
        units,
      });
    }).then(() => {
      self.loadProducts();
    });
  }

  render() {
    const favoriteTable = <Table hasLoaded={this.state.hasLoaded} visualFeedback={false} columnSizing={this.state.columnSizing} countRows={false} headers={this.state.headers} data={this.state.data} skipDataColumns={this.state.skipDataColumns} identifierIndex={this.state.identifierIndex} />;
    const saveButton = <button onClick={this.saveFavorites} type="button primary" className="btn btn-primary btn-lg btn-block" disabled={(this.state.changedProduct.length === 0)}>Spara</button>;
    let infoDiv;
    if (this.state.showInfoMessage) {
      infoDiv = (
        <div className="alert alert-primary" role="alert">
          {this.state.infoMessage}
        </div>
      );
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-8">
              <h1>
                Favoriter
              </h1>
              <p>
                Här kan du se artiklarna du har lagt till i dina favoriter. Mängden sparas inte.
              </p>
              <p>
                {this.state.intro}
              </p>
              {
                                this.state.showErrorModal
                                && (
                                <Modal outsideClickCloses={false} setModal={(value) => this.setModal(value)}>
                                  <div className="container">
                                    <div className="row">
                                      <div className="col-12">
                                        <h2>{this.state.error.title}</h2>
                                        <p>{this.state.error.description}</p>
                                      </div>
                                    </div>
                                  </div>
                                </Modal>
                                )
                            }
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col">
                  <SearchBarFavorite clickFunction={this.addProductToFavorite} inactivated={false} />
                </div>
                <div className="col-auto">
                  {
                                        ((this.state.showImages)
                                          ? <button style={{ backgroundSize: '38px 38px' }} rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="" onClick={this.toggleImages} className="btn btn-svg icon lg  btn-lg float-left show-image toggle btn-primary" />
                                          : <button style={{ backgroundSize: '38px 38px' }} rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="" onClick={this.toggleImages} className="btn btn-svg icon lg  btn-lg float-left show-image btn-outline-primary" />
                                        )
                                    }
                  <button onClick={this.reload} rel="tooltip" data-toggle="tooltip" data-placement="bottom" type="button" className="btn btn-lg btn-outline-primary float-right reload-icon ml-2" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {infoDiv}
                  <div className="border rounded">
                    { favoriteTable }
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 mb-0">
                  <hr className="mt-4" />
                  { saveButton }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Order;
