import React from 'react';
import { Link } from 'react-router-dom';
import axios from './bin/axios_gsh';
import Table from './components/Table';
import Modal from './components/Modal.tsx';
import SearchBarFavorite from './components/SearchBarFavorite';
import DisplayError from './components/DisplayError.tsx';

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userid: null,
      owner: false,
      id: parseInt(this.props.id, 10),
      identifierIndex: 0,
      meta: {},
      headers: ['Inaktiv', 'UID', 'Artikel nr.', '', 'Land', 'Pris', 'Enhet', 'Kollistorlek', 'Delbar', 'Enhet & Mängd', ''],
      originalTemplate: [],
      data: [],
      products: [],
      skipDataColumns: [0, 1],

      units: [],
      title: '',
      intro: '',

      replacements: [],
      productStatus: [],
      changedProducts: [],
      searchProducts: [],
      valid: [],

      error: null,
      validationErrors: null,

      showInfoBox: false,
      infoMessage: '',

      changesMade: false,
      saved: false,
      hasLoaded: false,

      addUID: 0,

      modal: false,
    };
    this.loadData = this.loadData.bind(this);
    this.reload = this.reload.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.saveTemplate = this.saveTemplate.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.showModal = this.showModal.bind(this);
    this.informAboutAddedProduct = this.informAboutAddedProduct.bind(this);
  }

  reload() {
    this.setState({
      originalTemplate: [],
      data: [],
      replacements: [],
      changedProducts: [],
      hasLoaded: false,
      changesMade: false,
      valid: [],

      addUID: 0,
    }, () => {
      this.loadData();
    });
  }

  showModal(open) {
    this.setState({
      modal: open,
    });
  }

  componentDidMount() {
    const self = this;
    axios.get('user/current').then((response) => {
      const user = response.data;

      if (!user.error) {
        const userid = user.ID;
        axios.get('product/units').then((units) => {
          units = units.data;

          self.setState({
            userid,
            units,
          });
        });
      } else {
        self.setState({
          error: <DisplayError {...response.data.error} />,
        });
      }
    }).then(() => {
      self.loadData();
    });
  }

  updateData() {
    const self = this;
    let intro = '';
    const products = [
      ...this.state.products,
    ];

    for (let i = 0; i < this.state.changedProducts.length; i++) {
      const changedProduct = {
        ...this.state.changedProducts[i],
      };
      for (let j = 0; j < products.length; j++) {
        const replace = products[j];
        if (changedProduct.UID === replace.UID) {
          products[j] = changedProduct;
          break;
        }
      }
    }

    const newData = [];
    for (let i = 0; i < products.length; i++) {
      const product = { ...products[i] };

      const select = [];
      const enhet = (product.Pris_per_styck) ? 'st' : 'kg';

      for (let i = 0; i < self.state.units.length; i++) {
        const unit = self.state.units[i];

        if ((product.Ej_Delbar && unit.ID !== 3) || (product.Pris_per_styck && unit.ID === 1) || (!product.Pris_per_styck && unit.ID === 2)) {
          // Don't add option
          if (product.EnhetID === 1 && product.Pris_per_styck && unit.ID === 1) {
            product.EnhetID = 2;
          } else if (product.EnhetID === 2 && !product.Pris_per_styck && unit.ID === 2) {
            product.EnhetID = 1;
          }
        } else {
          const kolli = (unit.ID === 3) ? ` (${enhet})` : '';
          select.push(<option value={unit.ID} key={unit.ID}>{ unit.Namn + kolli }</option>);
        }
      }

      const form = (
        <form className="float-left">
          <div className="row">
            <div className="col">
              <select onChange={((this.state.owner) ? ((e) => self.handleChange(e, 'unit')) : ((e) => {}))} className="custom-select" name={i} value={product.EnhetID} disabled={(select.length === 1)} rel="tooltip" data-toggle="tooltip" data-placement="bottom" title={(select.length === 1) ? 'Går ej att dela. Kan enbart beställa denna vara i enheter av kollin.' : ''}>
                { select }
              </select>
            </div>
            <div className="col">
              <input onChange={((this.state.owner) ? ((e) => self.handleChange(e, 'value')) : ((e) => {}))} className="text-right mr-auto form-control" type="number" pattern="[0-9]*" min="0" step={(product.EnhetID === 2) ? product.Stegom : (product.Ej_Delbar || product.EnhetID !== 1) ? '1' : '0.1'} name={i} value={product.Antal} />
            </div>
          </div>
        </form>
      );

      const trash = (this.state.owner) ? <button onClick={self.deleteProduct} type="button" className="btn btn-danger icon-custom small float-right" name={i}><img src="/../icon/trash2.0.png" alt="Ta bort" /></button> : <input type="hidden" />;

      const newProduct = {
        Inactive: !product.Aktiv,
        UID: product.UID,
        Nummer: <Link to={`/product/${product.ProduktID}`} target="_blank">{ product.Nummer }</Link>,
        Benämning: <Link to={`/product/${product.ProduktID}`} target="_blank">{ product.Benämning }</Link>,
        Landskod: product.Landskod,
        Pris: (product.PrisFinns) ? product.Pris.toFixed(2).replace('.', ',') : '-',
        Enhet: (product.Pris_per_styck) ? 'st' : 'kg',
        Förpackningsstorlek: product.Förpackningsstorlek,
        Ej_Delbar: (product.Ej_Delbar) ? '-' : 'ja',
        Form: (product.PrisFinns) ? form : product.PrisText,
        Delete: trash,
      };

      if (intro === '' && !product.Aktiv) {
        intro = 'Det finns artiklar som har gått ut ur sortimentet, dessa listas nedan med gul markering och kan inte läggas till i varukorgen.';
      }
      newData.push(newProduct);
    }

    this.setState({
      data: newData,
      intro,
    });
  }

  isChanged(product) {
    // Returns index position if found, else false
    for (let i = 0; i < this.state.changedProducts.length; i++) {
      if (this.state.changedProducts[i].UID === product.UID) {
        return i;
      }
    }
    return false;
  }

  addProduct(event, product) {
    const newProduct = {
      UID: `A${this.state.addUID}`, ...product, EnhetID: 3, Antal: 1,
    };

    this.setState({
      products: [
        newProduct,
        ...this.state.products,
      ],
      saved: false,
      changesMade: true,
      addUID: this.state.addUID + 1,
    }, () => {
      this.updateData();
    });
  }

  handleChange(event, type) {
    event.preventDefault();

    const target = event.currentTarget;
    let position = null;
    let found = false;

    if (target.name) {
      if (type !== 'replace') {
        found = true;
        position = parseInt(target.name, 10);
      } else {
        position = parseInt(target.name, 10);

        for (let i = 0; i < this.state.product.length && found === false; i++) {
          const row = this.state.product[i];

          if (row.ProduktID === this.state.replacements[position].ProduktID) {
            found = true;
            position = i;
            break;
          }
        }
      }
    }

    if (found) {
      let changedProducts = [...this.state.changedProducts];

      const targetProduct = this.state.products[position];
      const isChanged = this.isChanged(targetProduct);

      const changedProduct = (isChanged === false) ? { ...this.state.products[position] } : { ...this.state.changedProducts[isChanged] };

      switch (type) {
        case 'value':
          changedProduct.Antal = target.value;
          changedProduct.Antal = (targetProduct.EnhetID === 2) ? Math.round(changedProduct.Antal / targetProduct.Stegom) * targetProduct.Stegom : changedProduct.Antal;

          break;
        case 'unit':
          changedProduct.EnhetID = parseInt(target.value, 10);
          changedProduct.Antal = (changedProduct.EnhetID === 3) ? Math.round(changedProduct.Antal) : (changedProduct.EnhetID === 2) ? Math.round(changedProduct.Antal / targetProduct.Stegom) * targetProduct.Stegom : changedProduct.Antal;
          break;
        default:
          break;
      }

      if (isChanged !== false) {
        changedProducts = [
          ...changedProducts.slice(0, isChanged),
          changedProduct,
          ...changedProducts.slice(isChanged + 1, changedProducts.length + 1),
        ];

        this.setState({
          changedProducts,

          changesMade: true,
          saved: false,
        }, () => {
          this.updateData();
        });
      } else {
        changedProducts.push(changedProduct);

        this.setState({
          changedProducts,

          changesMade: true,
          saved: false,
        }, () => {
          this.updateData();
        });
      }
    }
  }

  deleteProduct(event) {
    event.preventDefault();
    const target = event.currentTarget;
    let position = null;
    let found = false;

    if (target.name) {
      found = true;
      position = parseInt(target.name, 10);
    }

    if (found) {
      const targetProduct = this.state.products[position];
      const isChanged = this.isChanged(targetProduct);

      if (isChanged === false) {
        const products = [
          ...this.state.products.slice(0, position),
          ...this.state.products.slice(position + 1, this.state.products.length + 1),
        ];

        this.setState({
          products,

          changesMade: true,
          saved: false,
        }, () => {
          this.updateData();
        });
      } else {
        const products = [
          ...this.state.products.slice(0, position),
          ...this.state.products.slice(position + 1, this.state.products.length + 1),
        ];

        const changedProducts = [
          ...this.state.changedProducts.slice(0, isChanged),
          ...this.state.changedProducts.slice(isChanged + 1, this.state.changedProducts.length + 1),
        ];

        this.setState({
          products,
          changedProducts,

          changesMade: true,
          saved: false,
        }, () => {
          this.updateData();
        });
      }
    }
  }

  informAboutAddedProduct(existingProductsArr) {
    if (existingProductsArr.length > 0) {
      const products = existingProductsArr.map((product, i) => <li key={i}>{product}</li>);

      const message = (
        <div>
          Obs! Följade varor finns redan i din varukorg:
          <ul>{products}</ul>
        </div>
      );
      this.setState({
        showInfoBox: true,
        infoMessage: message,
      });
    } else {
      this.setState({
        showInfoBox: false,
        infoMessage: '',
      });
    }
  }

  async saveTemplate() {
    const self = this;
    const promises = [];

    const newProducts = [];
    const changedProducts = [];

    for (let i = 0; i < this.state.products.length; i++) {
      const newProduct = { ...this.state.products[i] };
      let inOriginal = false;

      for (let j = 0; j < this.state.originalTemplate.length; j++) {
        const product = this.state.originalTemplate[j];
        if (JSON.stringify(product) === JSON.stringify(newProduct)) {
          inOriginal = true;
          break;
        }
      }

      for (let j = 0; j < this.state.changedProducts.length; j++) {
        const product = { ...this.state.changedProducts[j] };
        if (newProduct.UID === product.UID) {
          changedProducts.push(product);
          break;
        }
      }

      if (!inOriginal) {
        newProducts.push(newProduct);
      }
    }

    for (let i = 0; i < newProducts.length; i++) {
      const newProduct = newProducts[i];

      for (let j = 0; j < changedProducts.length; j++) {
        const product = { ...changedProducts[j] };
        if (newProduct.UID === product.UID) {
          newProducts[i] = product;
          changedProducts.splice(j, 1);
          break;
        }
      }
    }

    const removedProducts = [];
    for (let i = 0; i < this.state.originalTemplate.length; i++) {
      const removed = { ...this.state.originalTemplate[i] };
      let found = false;

      for (let j = 0; j < this.state.products.length; j++) {
        const product = this.state.products[j];

        if (JSON.stringify(removed) === JSON.stringify(product)) {
          found = true;
          break;
        }
      }

      if (!found) {
        removedProducts.push(removed);
      }
    }

    const validProducts = [];
    let canSave = true;

    [...newProducts, ...changedProducts, ...removedProducts].forEach((product) => {
      const valid = this.validate(product);

      if (!valid.valid) {
        validProducts.push(valid);
        canSave = false;
      }
    });

    if (!canSave) {
      this.setState({
        valid: validProducts,
      });
    } else {
      newProducts.forEach((product) => {
        const newProduct = {
          ProduktID: product.ProduktID,
          templateid: this.state.id,
          antal: product.Antal,
          enhet: product.EnhetID,
          Ej_Delbar: product.Ej_Delbar,
          Pris_per_styck: product.Pris_per_styck,
          Stegom: product.Stegom,
        };

        const promise = (axios.post('order/template/row/add', newProduct));
        promises.push(promise);
      });

      changedProducts.forEach((product) => {
        const newProduct = {
          ID: product.ID,
          ProduktID: product.ProduktID,
          templateid: this.state.id,
          antal: product.Antal,
          enhet: product.EnhetID,
          Ej_Delbar: product.Ej_Delbar,
          Pris_per_styck: product.Pris_per_styck,
          Stegom: product.Stegom,
        };
        const promise = (axios.post('order/template/row/update', newProduct));
        promises.push(promise);
      });

      removedProducts.forEach((product) => {
        const newProduct = {
          ID: product.ID,
          ProduktID: product.ProduktID,
          templateid: this.state.id,
          antal: product.Antal,
          enhet: product.EnhetID,
          Ej_Delbar: product.Ej_Delbar,
          Pris_per_styck: product.Pris_per_styck,
          Stegom: product.Stegom,
        };

        const promise = (axios.delete('order/template/row/delete', { data: newProduct }));
        promises.push(promise);
      });
    }

    await Promise.all(promises).then(() => {
      self.setState({
        data: [],
        products: [],
        originalTemplate: [],
        changedProducts: [],
        valid: [],

        changesMade: false,
        saved: true,
        addUID: 0,
      }, () => {
        self.loadData();
      });
    });
  }

  validate(object) {
    let valid = true;
    let error = '';
    let row = null;

    const oneDecimal = new RegExp('(^\\d*(\\.|\\,)\\d{1}$)|(^\\d+$)');
    const integer = new RegExp('(^\\d+$)');

    if (!object.Antal) {
      error = 'Du har inte matat in ett antal. ';

      valid = false;
    } else if (!oneDecimal.test(object.Antal)) {
      error = 'Minsta viktenheten är 0,1 kg. ';

      valid = false;
    } else if (!integer.test(object.Antal) && (object.EnhetID === 2 || object.EnhetID === 3)) {
      error = 'Enheten kan endast hantera heltal. ';

      valid = false;
    }

    if (!valid) {
      for (let i = 0; i < this.state.data.length; i++) {
        const dataRow = this.state.data[i];
        if (object.ID === dataRow.ID) {
          row = i;
          break;
        }
      }
    }

    return { row, valid, error };
  }

  addToCart(force) {
    const self = this;
    axios.post('cart/add/template/', { templateID: this.state.id, force }).then((response) => {
      response = response.data;
      let errors = [];

      if (response.length > 0 && response[0].error) {
        for (let i = 0; i < response.length; i++) {
          if (response[i].error.title === 'Artikeln hittades inte') {
            errors = [];
            errors.push('Ett fel har skett, en eller fler artiklar i varukorgen är utgångna.');
            // Artikeln hittades inte i systemet
            break;
          }

          errors.push((response[i].error.data) ? `Artikel nr.${response[i].error.data.Nummer}, ${response[i].error.data.Benämning}: ${response[i].error.description}` : `${response[i].error.title}: ${response[i].error.description}`);
        }
      }

      if (response === true) {
        self.props.updateCart();
      }

      self.setState({
        modal: (self.state.modal === false && errors.length !== 0),
        validationErrors: errors,
      });
    });
  }

  loadData() {
    const self = this;

    axios.get(`order/template/${self.state.id}`).then((response) => {
      response = response.data;

      if (!response.error) {
        const tempproducts = response.data;

        const products = [];
        for (let i = 0; i < tempproducts.length; i++) {
          const product = tempproducts[i];
          const newProduct = {
            UID: i,
          };

          for (const k in product) newProduct[k] = product[k];
          products.push(newProduct);
        }

        self.setState({
          owner: self.state.userid === response.template.ID_Användare,
          title: `Inköpslista - ${response.template.Namn}`,

          originalTemplate: products,
          products,
          meta: response.template,

          replacements: /* TODO: response.replacements */[],
          hasLoaded: true,
        }, () => {
          self.updateData();
        });
      } else {
        self.setState({
          title: 'Inköpslista - ',
          intro: '',
          hasLoaded: true,
          error: <DisplayError {...response.error} />,
        });
      }
    });
  }

  render() {
    const templateTable = <Table hasLoaded={this.state.hasLoaded} visualFeedback={false} countRows={false} headers={this.state.headers} data={this.state.data} skipDataColumns={this.state.skipDataColumns} identifierIndex={this.state.identifierIndex} />;
    const saveButton = <button onClick={this.saveTemplate} type="button primary" className="btn btn-primary btn-lg btn-block" disabled={!this.state.changesMade}>Spara</button>;
    const savedMessage = (
      <div className="alert alert-success" role="alert">
        <p className="mb-0">Mallen har sparats!</p>
      </div>
    );
    const tooltipCart = (this.state.changedProducts.length === 0) ? 'Lägger till artiklarna i varukorgen' : 'Du måste spara mallen innan du lägger till artiklarna i varukorgen.';
    const cartButton = <button type="button" onClick={(e) => { e.preventDefault(); this.addToCart(false); }} className="btn btn-lg btn-gsh float-right" rel="tooltip" data-toggle="tooltip" data-placement="bottom" title={tooltipCart} disabled={this.state.changesMade}>Lägg i varukorg</button>;
    const forceCartButton = <button onClick={(e) => { e.preventDefault(); this.addToCart(true); }} type="button" className="btn btn-gsh" data-dismiss="modal">Lägg till ändå</button>;

    let infoDiv;
    if (this.state.showInfoBox) {
      infoDiv = (
        <div className="alert alert-primary" role="alert">
          {this.state.infoMessage}
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col-md-9">
              <h1>{ this.state.title || 'Inköpslista -'}</h1>
              <p>{ this.state.intro }</p>
              { this.state.error }
              {
                                this.state.modal
                                && (
                                <Modal outsideClickCloses={false} extraButton={forceCartButton} setModal={this.showModal} closeButtonText="Avbryt">
                                  <div className="container">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <h2>Ett fel har skett</h2>
                                        <p>Vissa av varorna i listan går inte att lägga till i varukorgen, vill du lägga till varorna ändå? Om du väljer att lägga till varorna i varukorgen ändå så kommer inte alla att komma med.</p>
                                        {this.state.validationErrors.map((error, i) => <p key={i}>{error}</p>)}
                                      </div>
                                    </div>
                                  </div>
                                </Modal>
                                )
                            }
            </div>
          </div>
          <div className="row">
            <div className={(this.state.replacements.length === 0) ? 'col-md-12' : 'col-md-9'}>
              <div className="row">
                <div className="col-md-6">
                  <SearchBarFavorite clickFunction={this.addProduct} inactivated={!(this.state.owner)} />
                </div>
                <div className="col-md-6">
                  { cartButton }
                  <button onClick={this.reload} rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="Ladda om" type="button" className="btn btn-lg btn-outline-primary float-right mr-2">↺</button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {infoDiv}
                  <div className="border rounded">
                    { templateTable }
                  </div>
                </div>
              </div>
              {
                                (this.state.valid.length <= 0) ? '' : (
                                  <div className="row">
                                    <div className="form-group col-md-12 mb-0">
                                      <hr className="mt-4" />
                                      <div className="alert alert-warning" role="alert">
                                        {
                                                this.state.valid.map((row, i) => (
                                                  <p key={i}>
                                                    { `# ${row.row + 1}: ${row.error}`}
                                                  </p>
                                                ))
                                            }
                                      </div>
                                    </div>
                                  </div>
                                )
}
              <div className="row">
                <div className="form-group col-md-12 mb-0">
                  <hr className="mt-4" />
                  { (this.state.saved) ? savedMessage : '' }
                  { (this.state.owner) ? saveButton : '' }
                </div>
              </div>
            </div>
            {
                            (this.state.replacements.length > 0)
                              ? (
                                <div className="col-md-3">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <h2 className="text-muted">Ersättningsvaror</h2>
                                      <p>När varor går ut ur sortiment så får de ibland en särskild ersättningsartikel, dessa listas nedan.</p>
                                      <ul className="list-group mb-3">
                                        {
                                                    this.state.replacements.map((row, i) => (
                                                      <button onClick={((this.state.owner) ? ((e) => this.handleChange(e, 'replace')) : ((e) => {}))} className={`list-group-item list-group-item-action pointer${(row.replaced) ? ' bg-primary text-white' : ''}`} name={i} key={i}>
                                                        <h6 className="my-2">
                                                          Ersätt
                                                          { row.originalBenämning }
                                                          {' '}
                                                          med
                                                          { row.Benämning }
                                                        </h6>
                                                        <small className="" />
                                                      </button>
                                                    ))
                                                }
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )
                              : ''
                        }
          </div>
        </div>
      </div>
    );
  }
}

export default Order;
