import React, { MouseEvent, ReactNode } from 'react';

interface AmountFormProps {
    unit: number;
    buyingPieces: boolean;
    sizeOptions: number;
    notDividable: boolean;
    canBuyPieces: boolean;
    updateProductAmount: (productId: string, amount: number) => void;
    buyProductPiece: (productId: string, buyingProductPieces: boolean) => void;
    addProduct: (product: { productID: string; amount: number; unit: number }) => void;
    productID: string;
  }
  
  interface AmountFormState {
    infoMessage: string | null;
    valid: boolean;
    unit: number;
    amount: number;
    step: number;
    kg: number;
    st: number;
    kolli: number;
    hasClicked: boolean;
    selectText: string;
    buyingPieces: boolean;
    added: boolean;
    stage: number;
  }

class AmountForm extends React.Component<AmountFormProps, AmountFormState> {
    constructor(props: AmountFormProps) {
        super(props);
        this.state = {
            infoMessage: null,
            valid: true,
            unit: 3,
            amount: 0,

            step: 1, // default select is kolli
            kg: 1,
            st: 2, 
            kolli: 3,

            hasClicked: false,
            selectText: (this.props.unit === 1) ? 'Vikt' : (this.props.unit === 2) ? 'Antal' : 'Kolli',
            buyingPieces: this.props.buyingPieces,
            added: false,
            stage: 0,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onChangeUnit = this.onChangeUnit.bind(this);
        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    
    onChangeAmount(event: React.FormEvent<HTMLInputElement>) {
        let amount = Number(event.currentTarget.value);

        this.setState({
            amount: amount
        });

        this.props.updateProductAmount(this.props.productID, amount);
    }

    onChangeUnit(event: MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        
        const unit = parseInt(event.currentTarget.value, 10);

        let textVal = '';
        let value = 0;
        let buyingProductPieces = false;
        switch (unit) {
            case this.state.kg: 
                value = 0.1;
                textVal = 'Vikt';
                break;
            case this.state.st: 
                value = this.props.sizeOptions;
                textVal = 'Antal';
                buyingProductPieces = true;
                break;
            case this.state.kolli:
                value = 1;
                textVal = 'Kolli';
                break;
            default:
                break;
        }
         this.props.buyProductPiece(this.props.productID, buyingProductPieces);

        this.setState({
            selectText: textVal,
            unit,
            step: value,
        });
    }

    onSubmit(event: MouseEvent<HTMLButtonElement>) {
      event.preventDefault();
  
      if (this.state.stage === 0) {
        this.setState({
          stage: 1,
        });
      } else {
        let { amount } = this.state;
        const unit = parseInt(this.state.unit.toString(), 10);
        const { productID } = this.props;
        const oneDecimal = new RegExp('(^\\d*(\\.|\\,)\\d{1}$)|(^\\d+$)');
        const integer = new RegExp('(^\\d+$)');
  
        if (!amount || amount <= 0) {
          this.setState({
            valid: false,
            infoMessage: 'Du har inte matat in antal som är större än 0.',
          });
        } else if (!oneDecimal.test(amount.toString())) {
          this.setState({
            valid: false,
            infoMessage: 'Minsta viktenheten är 0,1 kg.',
          });
        } else if (unit === this.state.st && (amount % this.props.sizeOptions > 0)) {
          this.setState({
            valid: false,
            infoMessage: `Denna artikel köpes om ${this.props.sizeOptions} st`,
          });
        } else if (!integer.test(amount.toString()) && (unit === this.state.st || unit === this.state.kolli)) {
          this.setState({
            valid: false,
            infoMessage: 'Enheten kan endast hantera heltal.',
          });
        } else {
          this.setState({
            valid: true,
            infoMessage: null,
            added: true,
  
            hasClicked: false,
            stage: 0,
            amount: 0,
          });
          const product = { productID, amount, unit };
  
          this.props.addProduct(product);
        }
      }
    }
  
    onClick(event: MouseEvent<HTMLButtonElement>) {
      event.preventDefault();
  
      this.setState({
        hasClicked: true,
      });
    }
  
    onCancel(event: MouseEvent<HTMLButtonElement>) {
      event.preventDefault();
  
      this.setState({
        hasClicked: true,
        stage: 0,
      });
    }
  
    render(): ReactNode {
      const amount = <input type="number" onChange={this.onChangeAmount} className={`form-control rounded-0 amountform${(this.state.valid) ? '' : ' is-invalid'}`} aria-label="" ref="amount" placeholder={this.state.selectText} step={this.state.step} min={0} required />;
      let dropdown;
      
      if (!this.props.notDividable) {
        if (this.props.canBuyPieces) {
          dropdown = (
            <div className="col px-0">
              <button onClick={this.onClick} className="btn dropdown-toggle amountform rounded-0 border-right-0 w-100 btn-gsh" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {this.state.selectText}
              </button>
              <div className="dropdown-menu pointer">
                <button onClick={this.onChangeUnit} className="dropdown-item" type="button" value={this.state.kolli}>Kolli</button>
                <button onClick={this.onChangeUnit} className="dropdown-item" type="button" value={this.state.st}>Antal (st)</button>
              </div>
            </div>
          );
        } else {
          dropdown = (
            <div className="col px-0">
              <button onClick={this.onClick} className="btn dropdown-toggle rounded-0 border-right-0 w-100 btn-gsh" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{((this.state.hasClicked) ? this.state.selectText : 'Kolli')}</button>
              <div className="dropdown-menu pointer">
                <button onClick={this.onChangeUnit} className="dropdown-item" type="button" value={this.state.kolli}>Kolli</button>
                <button onClick={this.onChangeUnit} className="dropdown-item" type="button" value={this.state.kg}>Vikt (Kg)</button>
              </div>
            </div>
          );
        }
      } else {
        dropdown = (
          <div className="col px-0">
            <button className="btn btn-secondary dropdown-toggle amountform rounded-0 border-right-0 ej-delbar w-100" type="button" data-toggle="tooltip" data-placement="bottom" title="Ej Delbar" aria-haspopup="true" aria-expanded="false" disabled>Kolli</button>
            <div className="dropdown-menu pointer">
              <button className="dropdown-item" type="button" value={this.state.kolli}>Kolli</button>
            </div>
          </div>
        );
      }
  
      return (
        <div className="w-100">
          <div className="input-group w-100">
            {
                          ((this.state.stage === 0)
                            ? dropdown
                            : '')
                      }
            {
                          ((this.state.stage === 1)
                            ? amount
                            : '')
                      }
            <div className="input-group-append col-auto px-0">
              <button onClick={this.onSubmit} type="button" className="btn amountform float-right btn-gsh">
                {((this.state.stage === 0) ? <i className="font-weight-bold icon">+</i> : 'Köp')}
              </button>
            </div>
            {
                          ((this.state.stage === 1)
                            ? (
                              <div className="input-group-append">
                                <button onClick={this.onCancel} className="btn btn-danger amountform float-right">
                                  <i className="font-weight-bold icon rotate">+</i>
                                </button>
                              </div>
                            )
                            : '')
                      }
          </div>
          {(!this.state.valid)
            ? (
              <div className="alert alert-warning mt-1 mb-0 w-100" style={{ fontSize: '0.7rem' }} role="alert">
                {this.state.infoMessage}
              </div>
            )
            : ''}
        </div>
      );
    }
  }
  
  export default AmountForm;
  