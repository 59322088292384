import React, { Component, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import SmallCart from './SmallCart';
import Tools from './Tools';

interface NavTopProps {
  sitepage: string;
  user: {
    login: string;
    company: string;
  } | null;
  logout: boolean;
  setLogin: (user: any) => void;
  totalPrice: number;
  deliveryDate: string;
}

interface NavTopState {
  sitepage: string;
}

class NavTop extends Component<NavTopProps, NavTopState> {
  constructor(props: NavTopProps) {
    super(props);
    this.state = {
      sitepage: props.sitepage || '',
    };
  }

  logout(logout: boolean) {
    const self = this;
  
    if (logout) {
      const logoutResult = Tools.logout();
  
      if (logoutResult instanceof Promise) {
        logoutResult.then((response: boolean) => {
          self.props.setLogin(null);
          if (!response) {
            console.log('Error, could not sign out');
          }
        });
      } else {
        self.props.setLogin(null);
      }
    }
  }

  static getDerivedStateFromProps(prevProps: NavTopProps, prevState: NavTopState) {
    const sitepage = prevProps.sitepage || prevState.sitepage;

    return {
      sitepage,
    };
  }

  componentDidUpdate(prevProps: NavTopProps) {
    if (this.props.logout !== prevProps.logout) {
      this.logout(this.props.logout);
    }
  }

  render(): ReactElement {
    const logAction = (this.props.user !== null) && <button onClick={(e) => { e.preventDefault(); this.logout(true); }} className="btn btn-sm btn-link ml-5">Logga ut</button>;
    const cart = (this.props.user !== null) && <SmallCart totalPrice={this.props.totalPrice} deliveryDate={this.props.deliveryDate} />;

    return (
      <div id="main-navbar" className="px-3">
        <div className="row">
          <div className="col-auto">
            <div className="top-navbar-spacer" />
          </div>
          <div className="col-xl col-lg-12">
            <div className="row d-flex justify-content-center">
              <div className="col-12 col-xl-10 d-flex">
                {(this.props.user
                  ? (
                    <div className="ml-auto">
                      <span className="small">
                        Inloggad som:
                        {' '}
                        {`${this.props.user.login} (${this.props.user.company})`}
                        {logAction}
                      </span>
                    </div>
                  )
                  : (
                    <div className="ml-auto">
                      <span className="small">
                        <br />
                      </span>
                    </div>
                  )
                                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row top-navbar">
          <div className="col-auto d-flex align-items-center">
            <div className="top-navbar-spacer">
              <Link className="navbar-brand mr-0 float-left" to="/">
                <img className="mw-100 h-auto" src={process.env.REACT_APP_IMG_LOGO_PATH} id="brand" alt="Företagslogga" />
              </Link>
            </div>
          </div>
          <div className="col-xl col-lg-12">
            <div className="row d-flex justify-content-center py-3">
              <div className="col-12 col-xl-10 d-flex">
                {(this.props.user
                  ? (
                    <nav className="navbar navbar-light w-100 px-0">
                      <ul className="nav ml-xl-auto ml-0">
                        <li className="nav-item">
                          <Link className={`nav-link d-inline-block${(this.state.sitepage === 'stock' || this.state.sitepage === 'index') ? ' text-dark active' : ' text-dark'}`} to="/">Utbud</Link>
                        </li>
                        <li className="nav-item">
                          <Link className={`nav-link d-inline-block${(this.state.sitepage === 'favorites') ? ' text-dark active' : ' text-dark'}`} to="/favorites">Favoriter</Link>
                        </li>
                        <li className="nav-item">
                          <Link className={`nav-link d-inline-block${(this.state.sitepage === 'lists') ? ' text-dark active' : ' text-dark'}`} to="/lists">Inköpslistor</Link>
                        </li>
                        <li className="nav-item">
                          <Link className={`nav-link d-inline-block${(this.state.sitepage === 'orders') ? ' text-dark active' : ' text-dark'}`} to="/orders">Mina ordrar</Link>
                        </li>
                      </ul>
                      <div className="ml-xl-5 ml-auto">
                        <div className="float-left">
                          {cart}
                        </div>
                        <div className="float-left">
                          <Link rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="Konton" style={{ backgroundImage: "url('/../icon/account_circle-24px.svg')" }} className="btn btn-svg icon lg icon-custom nav-icon ml-4" to="/account" />
                        </div>
                      </div>
                    </nav>
                  )
                  : '')}
              </div>
            </div>
          </div>          
        </div>
      </div>
    );
  }
}

export default NavTop;
