import React, { ReactNode, MouseEvent } from 'react';

interface ModalProps {
  children: ReactNode;
  extraButton?: ReactNode;
  closeButtonText?: string;
  outsideClickCloses?: boolean;
  setModal: (isOpen: boolean) => void;
}

interface ModalState {
  content: ReactNode;
  extraButton: ReactNode;
  closeButtonText: string;
  outsideClickCloses: boolean;
}

class Modal extends React.Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);
    this.state = {
      content: props.children,
      extraButton: props.extraButton || '',
      closeButtonText: props.closeButtonText || 'Stäng',
      outsideClickCloses: props.outsideClickCloses === undefined ? true : props.outsideClickCloses,
    };
    this.outsideModal = this.outsideModal.bind(this);
  }

  static getDerivedStateFromProps(nextProps: ModalProps, prevState: ModalState): ModalState {
    const content = prevState.content || nextProps.children;
    const extraButton = prevState.extraButton || nextProps.extraButton;

    return {
      content,
      extraButton,
      closeButtonText: prevState.closeButtonText,
      outsideClickCloses: prevState.outsideClickCloses,
    };
  }

  outsideModal(e: MouseEvent<HTMLDivElement>): void {
    e.preventDefault();
    if (this.state.outsideClickCloses) {
      if (e.currentTarget === e.target) {
        this.props.setModal(false);
      }
    }
  }

  render(): ReactNode {
    return (
      <div onClick={this.outsideModal} className="modal fixed-modal fade show" tabIndex={-1} role="dialog" aria-labelledby="orderModal" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button onClick={(e) => { e.preventDefault(); this.props.setModal(false); }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{this.state.content}</div>
            <div className="modal-footer">
              {this.state.extraButton}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  this.props.setModal(false);
                }}
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                {this.state.closeButtonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
