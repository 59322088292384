import React, { Component } from 'react';
import {
  Switch, Route, Router, Link,
} from 'react-router-dom';
import axios from '../bin/axios_gsh';
import PriceList from './PriceList';
import DownloadList from './DownloadList';
import history from './BrowserHistory';

interface Filter {
  name: string;
  checked: boolean;
}

interface Category {
  id: number;
  name: string;
  isLoading: boolean;
}

interface CategoryData {
  ID: number;
  Namn: string;
}

interface NavSideProps {
  filter: Filter[] | null;
  user: any; 
  view: string; 
  categoryID: number; 
  setFilter: (filter: Filter[]) => void;
}

interface NavSideState {
  categories: Category[];
  filter: Filter[];
  isLoaded: boolean;
}

class NavSide extends Component<NavSideProps, NavSideState> {
  constructor(props: NavSideProps) {
    super(props);
    this.state = {
      categories: [],
      filter: this.props.filter !== null
        ? this.props.filter
        : [
          { name: 'Eko', checked: false },
          { name: 'Krav', checked: false },
          { name: 'Närodlat', checked: false },
          { name: 'Svenskt', checked: false },
          { name: 'Avtalsartiklar', checked: false },
        ],
      isLoaded: false,
    };
    this.Catalog = this.Catalog.bind(this);
    this.loadData = this.loadData.bind(this);
    this.setFilter = this.setFilter.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    if (!this.state.isLoaded) {
      this.loadData();
    }
  }

  setFilter(e: React.ChangeEvent<HTMLInputElement>) {
    const target = e.currentTarget;
    const index = this.state.filter.findIndex((element) => element.name === target.name);
    const newFilter = JSON.parse(JSON.stringify(this.state.filter));
    newFilter[index].checked = !this.state.filter[index].checked;
    this.props.setFilter(newFilter);
  }

  static getDerivedStateFromProps(nextProps: NavSideProps, prevState: NavSideState) {
    const filter = nextProps.filter ? nextProps.filter : prevState.filter;
    return {
      filter,
    };
  }

  loadData() {
    axios.get<CategoryData[]>('product/categories').then((response) => {
      const categories: Category[] = [];
      response.data.forEach((element: CategoryData) => {
        const temp: Category = {
          id: element.ID,
          name: element.Namn,
          isLoading: false,
        };
        categories.push(temp);
      });
      this.setState({
        categories,
        isLoaded: true,
      });
    });
  }

  render() {
    return (
      <nav
        className="sidebar h-100 d-xl-inline-block navbar-collapse collapse mt-4"
        id="sidebar"
      >
        <div className="nav-list">
          <div className="float-left">
            <button
              className="btn btn-danger d-block d-xl-none position-relative float-right rounded-circle text-center"
              id="sidebar-toggle"
              type="button"
              data-toggle="collapse"
              data-target="#sidebar"
              aria-controls="sidebar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            />
          </div>

          {
            (this.props.user === null) ? '' : (
              <div className="bg-white px-3 rounded mini-menu">
                <h6 className="font-weight-bold py-3 mb-0 d-flex justify-content-between">
                  <span>Kategorier</span>
                  <a className="d-flex align-items-center text-dark collapse-button" data-toggle="collapse" href="#categoriesCollapse" aria-expanded="true" aria-controls="categoriesCollapse" />
                </h6>
                <div className="collapse multi-collapse overflow-hidden show pb-3" id="categoriesCollapse">
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <Link className={(`nav-link${this.props.view === 'campaign' ? ' active' : ''}`)} to="/stock/campaign">Kampanjvaror</Link>
                    </li>
                    {
                      this.state.categories.map((category, i) => (
                        <li className="nav-item" key={i}>
                          <Link className={`nav-link${category.id === this.props.categoryID ? ' active' : ''}`} to={`/stock/category/${category.id}`}>
                            {category.name}
                          </Link>
                        </li>
                      ))
                    }
                    <li className="nav-item">
                      <hr />
                    </li>
                    <li className="nav-item">
                      <Link className={(`nav-link${this.props.view === 'agreement' ? ' active' : ''}`)} to="/stock/agreement">Avtalsartiklar</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={(`nav-link${this.props.view === 'all' ? ' active' : ''}`)} to="/stock/all">Alla artiklar</Link>
                    </li>
                  </ul>
                </div>
              </div>
            )
          }
          <Router history={history}>
            <Switch>
              <Route path="/index" component={this.Catalog} />
              <Route path="/stock" component={this.Catalog} />
              <Route path="/product" component={this.Catalog} />
            </Switch>
          </Router>
        </div>
      </nav>
    );
  }

  Catalog() {
    if (this.props.user !== null) {
      return (
        <div>
          <div className="bg-white px-3 rounded mini-menu mt-4">
            <h6 className="font-weight-bold py-3 mb-0 d-flex justify-content-between text-muted">
              <span>Filter</span>
              <a className="d-flex align-items-center text-muted collapse-button" data-toggle="collapse" href="#filterCollapse" role="button" aria-expanded="false" aria-controls="filterCollapse" />
            </h6>
            <div className="collapse multi-collapse overflow-hidden show pb-3" id="filterCollapse">
              <ul className="nav flex-column">
                {
                  (this.state.filter !== null && this.state.filter.length > 0)
                    ? this.state.filter.map((filter, i) => (
                      <div className="nav-item" key={i}>
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input pointer" name={filter.name} checked={filter.checked} onChange={this.setFilter} value={filter.name} />
                          <label className="form-check-label">
                            {filter.name}
                          </label>
                        </div>
                      </div>
                    ))
                    : null
                }
              </ul>
            </div>
          </div>
          { process.env.REACT_APP_SHOW_LINKS === 'true' &&
            <DownloadList />
          }
          {process.env.REACT_APP_SHOW_PRICELIST === 'true' &&
            <div className="bg-white px-3 rounded mini-menu mt-4">
              <h6 className="font-weight-bold py-3 mb-0 d-flex justify-content-between text-muted">
                <span>{ process.env.REACT_APP_PRICELIST_TITLE || 'Prislista' }</span>
                <a className="d-flex align-items-center text-muted collapse-button" data-toggle="collapse" href="#prislistaCollapse" role="button" aria-expanded="false" aria-controls="prislistaCollapse" />
              </h6>
              <div className="collapse multi-collapse overflow-hidden show pb-3" id="prislistaCollapse">
                <ul className="nav flex-column">
                  <PriceList categories={this.state.categories} categoryID={this.props.categoryID} filter={this.state.filter} view={this.props.view}  />
                </ul>
              </div>
            </div>
          }
        </div>
      );
    }
    return (
      ''
    );
  }
}

export default NavSide;
