import React, { ChangeEvent, FormEvent, ReactNode } from 'react';

interface FormProps {
  sublabel: string;
}

interface FormState {
  sublabel: string;
  send: Record<string, unknown>;
  inputStyle: {
    danger: string;
    warning: string;
    default: string;
  };
  textHelpStyle: {
    danger: string;
    default: string;
  };
  [key: string]: string | Record<string, unknown> | ((event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void) | Record<string, string>;
}

class Form extends React.Component<FormProps, FormState> {
  constructor(props: FormProps) {
    super(props);
    this.state = {
      sublabel: props.sublabel,
      send: {},
      inputStyle: {
        danger: 'form-control border border-danger',
        warning: 'form-control border border-warning',
        default: 'form-control',
      },
      textHelpStyle: {
        danger: 'visible',
        default: 'invisible',
      },
    };

    this.beforeChange = this.beforeChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.afterChange = this.afterChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  beforeChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    // Ran before handleChange
    // Override
  }

  handleChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    this.beforeChange(event);
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.afterChange(event);
      }
    );
  }

  afterChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    // Ran after handleChange
    // Override
  }

  handleSubmit(event: FormEvent) {
  }

  render(): ReactNode {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label htmlFor="" />
          <select
            className="form-control"
            value={this.state.sublabel}
            onChange={(event) => this.handleChange(event)}
            name="gh_select"
            id="gh_select"
          >
            <option>1</option>
            <option>2</option>
            <option>3</option>
          </select>
        </div>
        <div className="form-group row">
          <input
            type="password"
            value={this.state.sublabel}
            onChange={(event) => this.handleChange(event)}
            className="form-control"
            name="gh_username"
            id="gh_username"
            placeholder="Användarnamn"
          />
          <small id="helpId" className="form-text text-muted">
            Case sensitive
          </small>
        </div>
        <div className="form-group row">
          <button type="submit" className="btn btn-primary col-md-12">
            Logga in
          </button>
        </div>
      </form>
    );
  }
}

export default Form;