import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import XLSX from 'xlsx';
import axios from '../bin/axios_gsh';
import SmallLoading from './SmallLoading';

interface PriceListProps {
  categories?: CategoryReport[];
  categoryID: Number;
  filter: FilterItem[];
  view: string;
}

interface FilterItem {
  name: string;
  checked: boolean;
}

interface PriceListState {
  categories: CategoryReport[];
  baseList: any[];
  sortedList: any[];
  modifiedObjectList: any[];
  categoryID: number;
  lastCategoryID: number;
  startingAtRow: number;
  hasLoaded: boolean;
  type: string;
  logoImagePath: string;
  showPDF: boolean;
  showEXCEL: boolean;
  prevOffset: number;
  totalAmRows: number;
  showError: boolean;
  errorMessage: React.JSX.Element | null;
  filterReports: boolean;
}

interface Category {
  id: number;
  isLoading: boolean;
  name: string;
}

interface ProductInfo {
  itemnr: string;          
  name: string;            
  priceExists: boolean;
  category: number;         
  priceForOne: number;     
  canBuyPieces: boolean;
}

interface CategoryReport {
  id: number;
  name: string;
}

interface Item {
  itemnr: string;
  name: string;
  priceForOne: string;
}

interface ArrayElement {
  category: string;
  tempArray: Item[];
}

class PriceList extends React.Component<PriceListProps, PriceListState> {
  constructor(props: PriceListProps) {
    super(props);
    this.state = {
      categories: props.categories ? props.categories : [],
      baseList: [],
      sortedList: [],
      modifiedObjectList: [],
      categoryID: 0,
      lastCategoryID: 1,
      startingAtRow: 0,
      hasLoaded: true,
      type: 'E',
      logoImagePath: process.env.REACT_APP_IMG_LOGO_PATH || '',
      showPDF: process.env.REACT_APP_SHOW_PRICELIST_PDF === 'true',
      showEXCEL: process.env.REACT_APP_SHOW_PRICELIST_EXCEL === 'true',
      prevOffset: 0,
      totalAmRows: 0,
      showError: false,
      errorMessage: null,
      filterReports: false,
    };

    this.getProducts = this.getProducts.bind(this);
    this.modifyObjectList = this.modifyObjectList.bind(this);
    this.printExcel = this.printExcel.bind(this);
    this.printPDF = this.printPDF.bind(this);
    this.sortList = this.sortList.bind(this);
    this.getCategoryProductList = this.getCategoryProductList.bind(this);
    this.setFilterReports = this.setFilterReports.bind(this);
  }

  static getDerivedStateFromProps(nextProps: PriceListProps, prevState: PriceListState) {
    const categories = nextProps.categories
      ? nextProps.categories
      : prevState.categories;

    if (categories !== prevState.categories && categories.length > 0) {
      return {
        categories,
        lastCategoryID: categories[categories.length - 1].id,
      };
    }
    return null;
  }

  componentDidMount() {
    let lastID = 1;
    const { categories } = this.props;
    
    if(categories){
      for (let i = 0; i < categories.length; i++) {
        if (lastID < categories[i].id) {
          lastID = categories[i].id;
        }
      }
    }    

    this.setState({
      lastCategoryID: lastID,
    });
  }

  getProducts() {
    const self = this;    
    const { view } = this.props;
    const params = {
      categoryID: this.state.filterReports && view === 'category' ? this.props.categoryID : null,
      campaign: view === 'campaign' && this.state.filterReports,
    };

    axios
      .post('product/perCategory', params)
      .then((response) => {
        let filteredArray: any[] = [];

        if(this.state.filterReports) {
          filteredArray = response.data;
          if (view === 'agreement') {
            filteredArray = filteredArray.filter(e => e.Avtalsartikel === true);
          }
        }

        const arr: any[] = filteredArray.length > 0 ? filteredArray : response.data;
        const updatedArr: ProductInfo[] = []; 
        let totRows = 1;
        if (Array.isArray(arr) && arr.length > 0) {
          totRows = arr[0].totaltAntalRader;
          arr.forEach((element) => {
            const productInfo: ProductInfo = {
              itemnr: element.Nummer,
              name: element.Benämning,
              priceExists: element.PrisFinns,
              category: element.ID_Kategori,
              priceForOne: element.Pris,
              canBuyPieces: element.Pris_per_styck,
            };           

            updatedArr.push(productInfo); 
          });

          if (arr.length > 0) {
            const temp = self.state.startingAtRow + 50;
            self.setState({
              baseList: [...self.state.baseList, ...arr],
              prevOffset: self.state.startingAtRow,
              totalAmRows: totRows,
              startingAtRow: temp,
            });
          }

          this.modifyObjectList();
        }       
      })
      .catch((error) => {
        self.setState({
          showError: true,
          errorMessage: (
            <div>Vi kunde inte hämta artiklarna. Försök ladda om sidan.</div>
          ),
        });
      });
  }

  sortList() {
    const listToSort = this.state.baseList;

    listToSort.sort((a, b) => a.category - b.category);
    
    this.setState({
      sortedList: listToSort,
    });
  }

  modifyObjectList() {
    const arr = this.state.sortedList.length > 0 ? this.state.sortedList : this.state.modifiedObjectList;
    const { categories } = this.props;
    const array: ArrayElement[] = [];
    let filteredCategories: any[] | undefined = [];

    if(this.state.filterReports && this.props.categoryID && categories){
      filteredCategories = categories.filter(c => c.id == this.props.categoryID);
    }
    else {
      filteredCategories = categories;
    }

    if(!filteredCategories){
      return;
    }

    for (let i = 0; i < filteredCategories.length; i++) {  
      let arrayElement: ArrayElement | null = null;
      const foundCategory = filteredCategories.find(c => filteredCategories && c.id === filteredCategories[i].id); 

      if (foundCategory) {
          arrayElement = {
            category: '',
            tempArray: [] as Item[],
          }
          arrayElement.category =  foundCategory.name;          
      }        

      let pricesByCategory = arr.filter(p => filteredCategories && p.ID_Kategori === filteredCategories[i].id);

      if(this.state.filterReports){
        let filteredPrices = pricesByCategory;
        for(let l = 0; l < this.props.filter.length; l++) {
          let filterItem = this.props.filter[l];
          if (filterItem.checked) {
            switch (filterItem.name) {
              case "Eko":
                filteredPrices = filteredPrices.filter(p => p.Eko === 1);
                break;
              case "Krav":
                filteredPrices = filteredPrices.filter(p => p.Krav === 1);
                break;
              case "Svenskt":
                filteredPrices = filteredPrices.filter(p => p.Svenskt === 1);
                break;
              case "Närodlat":
                filteredPrices = filteredPrices.filter(p => p.Närodlat === 1);
                break;
              case "Avtalsartikel":
                filteredPrices = filteredPrices.filter(p => p.Avtalsartikel === 1);
                break;         
            }       
          }
        }

        pricesByCategory = filteredPrices;
      }

      for(let j = 0; j < pricesByCategory.length; j++)
      {
        const unit = pricesByCategory[j].canBuyPieces ? 'st' : 'kg';
        let e: Item = {
          itemnr: pricesByCategory[j].Nummer,
          name: pricesByCategory[j].Benämning,
          priceForOne: pricesByCategory[j].Pris_per_styck
            ? `${pricesByCategory[j].jmfr_pris} / ${unit}`
            : 'Inget pris',
        }
        if(arrayElement){
          arrayElement.tempArray.push(e);                  
        }
      }

      if(arrayElement){
        array.push(arrayElement);
        arrayElement = null;
      }     
    }

    this.setState({ modifiedObjectList: array }, () => {
      if(this.state.type === 'E'){
        this.printExcel();
      }else {
        this.printPDF();
      }
    });
  }

  componentDidUpdate(prevProps: PriceListProps, prevState: PriceListState) {
    if (this.state.baseList !== prevState.baseList) {
      this.sortList();
    }
  }

  async getCategoryProductList(type?: string) {
    this.setState({
      sortedList: [],
      modifiedObjectList: [],
      baseList: [],
      type: type ? type : 'E',
      hasLoaded: false,
    });
    await this.getProducts();     
  }

  getTodaysDate() {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; // January is 0!
    const yyyy = today.getFullYear();
    let day = '';
    let month = '';

    if (dd < 10) {
      day = `0${dd}`;
    }
    if (mm < 10) {
      month = `0${mm}`;
    }
    return `${yyyy}-${month}-${day}`;
  }

  printExcel() {
    const today = this.getTodaysDate();
    let array = this.state.modifiedObjectList;
    array = this.state.modifiedObjectList.filter((e) => e.tempArray.length > 0);

    const worksheet = XLSX.utils.json_to_sheet(
      [
        { namn: process.env.REACT_APP_COMPANY_NAME },
        { namn: `Prislista för ${process.env.REACT_APP_COMPANY_NAME}` },
        { namn: `Skapad ${today}` },
      ],
      { skipHeader: true },
    );

    let addToSheet = 4;
    for (let i = 0; i < array.length; i++) {
        XLSX.utils.sheet_add_json(worksheet, [{ category: array[i].category, namn: 'Namn', priceForOne: 'Pris' }], {
          header: ['category', 'namn', 'priceForOne'],
          skipHeader: true,
          origin: addToSheet,
        });
        addToSheet++;
        XLSX.utils.sheet_add_json(worksheet, array[i].tempArray, {
          header: ['itemnr', 'name', 'priceForOne'],
          skipHeader: true,
          origin: addToSheet,
        });
        addToSheet += 1 + array[i].tempArray.length;
    }

    const workbook = XLSX.utils.book_new();
    const columnWidths = [
      { wch: 8 }, 
      { wch: 40 },
      { wch: 13 },
    ];
    worksheet['!cols'] = columnWidths;
    XLSX.utils.book_append_sheet(workbook, worksheet, `Prislista-${today}`);
    XLSX.writeFile(workbook, `Prislista-${today}.xlsx`);
    this.setState({ hasLoaded: true });
  }

  async printPDF() {
    const today = this.getTodaysDate();
    const doc = new jsPDF();

    const loadImage = () => {
      return new Promise((resolve) => {
        const logoImage = new Image();
        logoImage.src = this.state.logoImagePath;
        logoImage.onload = () => {
          resolve(logoImage);
        };
      });
    };
    const logoImage = await loadImage();


    // Create Table
    const columns = [
      { title: 'Artikelnr', dataKey: 'itemnr' },
      { title: 'Namn', dataKey: 'name' },
      { title: 'Pris', dataKey: 'priceForOne' },
    ];

    let array = this.state.modifiedObjectList;
    array = this.state.modifiedObjectList.filter((e) => e.tempArray.length > 0);

    for (let i = 0; i < array.length; i++) {
        if (i > 0) {
          doc.addPage();
        }
    
        doc.addImage(logoImage, 'JPEG', 14, 10, 38, 16);
        doc.setFontSize(10);
        doc.setFontType('bold');
        doc.text(array[i].category, 14, 37);
  
        doc.autoTable(columns, array[i].tempArray, {
          theme: 'plain',
          styles: {
            cellPadding: 0,
            fontSize: 9,
          },
          margin: { top: 40 }, 
        });
    }
  
    doc.save(`prislista-${today}.pdf`);
    this.setState({ hasLoaded: true });
  }

  setFilterReports(){
    this.setState({
      filterReports: !this.state.filterReports
    })
  }

  render() {
    return (
      <SmallLoading
        hasLoaded={this.state.hasLoaded}
        title="Laddar prislista, detta kan ta någon minut..."
      >
        <div className="form-check">
            <input type="checkbox" className="form-check-input pointer" name='filterReports' checked={this.state.filterReports} onChange={this.setFilterReports}/>
            <label className="form-check-label">
              {'Enbart valda kategorier och filter'}
            </label>
        </div>
          {this.state.showPDF && (
            <li className="nav-item">
              <span
                className="nav-link d-flex align-items-center  pl-0 pointer"
                onClick={(event) => {
                  this.getCategoryProductList('P');
                }}
              >
                <img
                  src="/../icon/save_alt-24px.svg"
                  width="20px"
                  height="20px"
                  className="pr-1"
                  alt="Ladda ned"
                />
                Prislista PDF
              </span>
            </li>
          )}
          {this.state.showEXCEL && (
            <li className="nav-item">
              <span
                className="nav-link d-flex align-items-center  pl-0 pointer"
                onClick={(event) => {
                  this.getCategoryProductList('E');
                }}
              >
                <img
                  src="/../icon/save_alt-24px.svg"
                  width="20px"
                  height="20px"
                  className="pr-1"
                  alt="Ladda ned"
                />
                Prislista Excel
              </span>
            </li>
          )}
      </SmallLoading>
    );
  }
}

export default PriceList;
