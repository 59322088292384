import React, { ReactNode, useEffect, useState } from 'react';

interface LoadingProps {
  hasLoaded: boolean;
  children: ReactNode;
}

const Loading: React.FC<LoadingProps> = ({ hasLoaded, children }) => {
  const [content, setContent] = useState<ReactNode>(children);
  const [loaded, setLoaded] = useState<boolean>(hasLoaded);

  useEffect(() => {
    setContent(children);
    setLoaded(hasLoaded);
  }, [children, hasLoaded]);

  return loaded ? (
    content as React.ReactElement
  ) : (
    <div className="py-5 text-center">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Loading;
