import React from 'react';
import {Link} from 'react-router-dom';
import Modal from './Modal.tsx';

class SmallCart extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            modal: false
        }

        this.setModal = this.setModal.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps){
        if(this.props.deliveryDate !== prevProps.deliveryDate){
            this.setState({
                modal: true,
            });
        }
    }

    setModal(value){
        this.setState({
            modal: value
        });
    }

    render() {
        let date = new Date(this.props.deliveryDate);
        let day = (date.getUTCDate() < 10)? "0" + date.getUTCDate() : date.getUTCDate();
        let month = (date.getUTCMonth() + 1 < 10)? "0" + (date.getUTCMonth()+1) : (date.getUTCMonth()+1);

        date = month + "/" + day;

        return (
            <div className="cart">
                {
                    (this.state.openModal)?
                        <Modal  outsideClickCloses={false} setModal={this.setModal} >
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2>Leveransdatumet har uppdateras</h2>
                                        <p>Ditt valda leveransdatum har löpt ut.</p>
                                    </div>
                                </div>
                            </div>
                        </Modal> : ''
                }
                <div className="float-left pr-2 cart-text">
                    <div className="d-block">
                        <span className="font-weight-bold">{ ((this.props.totalPrice)? this.props.totalPrice : 0.0).toFixed(2).replace(".", ",") } kr</span>
                    </div>
                    <div className="d-block">
                        <span className="">Prel. leverans: { date }</span>
                    </div>
                </div>
                <div className="float-left">
                    <Link style={{backgroundImage:"url('/../icon/shopping_cart-24px.svg')"}} rel="tooltip" data-toggle="tooltip" data-placement="bottom" title='Varukorgen' className="btn btn-svg lg icon-custom nav-icon" to="/order">
                        
                    </Link>
                </div>
            </div>
        );
    }
}

export default SmallCart;