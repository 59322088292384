import React, { Component } from 'react';
import axios from './../bin/axios_gsh';
import Table from './Table';
import Pagination from './Pagination';

interface Order {
    ID: number;
    Leveransdatum: string;
    Skapad: string;
    Artiklar: string;
    StatusID: number;
    Status: string;
}

interface OrderResponse {
    ID: number;
    DeliveryDate: string;
    ReceivedDateTime: string;
    Namn: string;
    TotalRows: number;
    ID_Status: number;
}

interface OrdersState {
    headers: string[];
    columnSizing: (string | null)[];
    onClickFunction: (data: any) => void;
    identifierIndex: number;
    skipDataColumns: number[];
    data: OrderResponse[];
    hasLoaded: boolean;
    page: number;
    perPage: number;
    redirect: boolean;
}

interface Props {
onClickFunction: (orderid: number) => void;
}
  
class Orders extends Component<Props, OrdersState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            headers: ['ID', 'Leveransdatum', 'Skapad', 'Skapad av', 'Artiklar', 'StatusID', 'Status'],
            columnSizing: ['1%', '140px', '120px', null, '140px', '1%', '140px'],
            onClickFunction: this.props.onClickFunction,

            identifierIndex: 0,
            skipDataColumns: [0, 5],
            data: [],
            hasLoaded: false,
            page: 0,
            perPage: 5,
            redirect: false,
        };
    }
    componentDidMount() {
        let self = this;

        axios.get('order/status').then(function (response) {
            let statuses = response.data;

            axios.get<OrderResponse[]>('order/company').then(function (response) {
                const activeOrders = response.data;

                activeOrders.forEach((row: any) => {
                    let date = new Date(row.ReceivedDateTime);
                    let day = (date.getDate() < 10) ? "0" + date.getDate() : date.getDate(),
                        month = (date.getMonth() + 1 < 10) ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);

                    row.ReceivedDateTime = day + '/' + month + '/' + date.getFullYear();

                    date = new Date(row.DeliveryDate);
                    day = (date.getDate() < 10) ? ("0" + date.getDate()) : (date.getDate());
                    month = (date.getMonth() + 1 < 10) ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1);

                    row.DeliveryDate = day + '/' + month + '/' + date.getFullYear();

                    statuses.forEach((status: any) => {
                        if (row.ID_Status === status.ID) {
                            row.Status = status.Text;
                        }
                    });
                });

                self.setState({
                    redirect: false,
                    data: activeOrders,
                    hasLoaded: true
                });
            });
        });
    }
    componentWillUnmount() {
    }
    render() {
        let self = this;
        let onclick = function (data: any) {
            self.state.onClickFunction(data);
        };

        if (this.state.redirect) {
            return this.state.redirect;
        }

        let setPage = function (page: number) {
            self.setState({
                page: page,
            });
        };

        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>
                                Ordrar under process
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="border rounded">
                                <div className="px-0 col-md-12">
                                    <Table 
                                    page={this.state.page} 
                                    columnSizing={this.state.columnSizing} 
                                    perPage={this.state.perPage} 
                                    hasLoaded={this.state.hasLoaded} 
                                    clickFunction={onclick} tooltip={"Öppna"} 
                                    visualFeedback={true} 
                                    countRows={false} 
                                    headers={this.state.headers} 
                                    data={this.state.data} 
                                    skipDataColumns={this.state.skipDataColumns} 
                                    identifierIndex={this.state.identifierIndex} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <Pagination setPage={setPage} current={this.state.page} pages={((this.state.data.length / this.state.perPage) % 1 !== 0) ? Math.floor(this.state.data.length / this.state.perPage) + 1 : Math.floor(this.state.data.length / this.state.perPage)}>

                            </Pagination>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Orders;
