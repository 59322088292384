import React, { Component, ChangeEvent } from 'react';
import axios from '../bin/axios_gsh';
import Table from './Table';

interface Order {
  ID: number;
  ReceivedDateTime: string;
  ID_Användare_Plockatav: number;
  Namn: string;
  Status: string;
}

interface MonthData {
  month: string;
  data: Order[];
}

interface YearData {
  year: number;
  data: MonthData[];
}

interface OrderHistoryProps {
  onClickFunction: (data: any) => void;
}

interface OrderHistoryState {
  headers: string[];
  columnSizing: (string | null)[];
  months: string[];
  display: number;
  startYear: Date;
  oldestOrder: number;
  onClickFunction: (data: any) => void;
  skipDataColumns: number[];
  data: YearData[];
  result?: Order[];
}

class OrderHistory extends Component<OrderHistoryProps, OrderHistoryState> {
  constructor(props: OrderHistoryProps) {
    super(props);

    this.state = {
      headers: ['ID', 'Skapad', 'Skapad av', 'Skapad av', 'Status'],
      columnSizing: ['1%', '140px', '1%', null, '140px'],
      months: [
        'Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'
      ],
      display: 1,
      startYear: new Date(),
      oldestOrder: new Date().getUTCFullYear(),
      onClickFunction: this.props.onClickFunction,
      skipDataColumns: [0, 2],
      data: [],
    };

    this.showOlder = this.showOlder.bind(this);
    this.changeYear = this.changeYear.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    const self = this;

    axios.get('order/history').then((response) => {
      const history: Order[] = response.data;
      const current = new Date();

      let yearOfOldestOrder = parseInt(new Date().getUTCFullYear().toString(), 10);

      if (history.length > 0) {
        yearOfOldestOrder = parseInt(new Date(history[history.length - 1].ReceivedDateTime).getUTCFullYear().toString(), 10);
      }

      if (parseInt(current.getUTCFullYear().toString(), 10) - yearOfOldestOrder === 0) {
        yearOfOldestOrder = current.getUTCFullYear();
      }

      self.setState({
        result: history,
        oldestOrder: yearOfOldestOrder,
      }, () => {
        const data = self.loadData(history);

        self.setState({
          data,
        });
      });
    });
  }

  changeYear(e: ChangeEvent<HTMLButtonElement>) {
    const date = this.state.startYear;
    date.setUTCFullYear(parseInt(e.currentTarget.value, 10));

    this.setState({
      startYear: date,
      display: 1,
    }, () => {
      const data = this.loadData(this.state.result || []);
      this.setState({
        data,
      });
    });
  }

  loadData(history: Order[]) {
    history = [...history];
    const self = this;
    const years: YearData[] = [];

    for (let i = 0; i < (new Date().getUTCFullYear() - this.state.oldestOrder) + 1; i++) {
      const months: MonthData[] = [];
      const previousYear = new Date();
      previousYear.setUTCFullYear(previousYear.getUTCFullYear() - i);

      for (let j = (previousYear.getUTCFullYear() === (new Date()).getUTCFullYear() && i === 0) ? parseInt(previousYear.getUTCMonth().toString(), 10) : 11; j >= 0; j--) {
        const previousMonth = previousYear;
        const monthArr: Order[] = [];
        previousMonth.setUTCMonth(j);

        for (let k = 0; k < history.length; k++) {
          const order = history[k];
          const orderMonth = new Date(order.ReceivedDateTime);

          if (previousMonth.getUTCFullYear() === orderMonth.getUTCFullYear() && previousMonth.getUTCMonth() === orderMonth.getUTCMonth()) {
            let date = orderMonth;
            const day = (date.getDate() < 10) ? `0${date.getDate()}` : date.getDate();
            const month = (date.getMonth() + 1 < 10) ? `0${date.getMonth() + 1}` : (date.getMonth() + 1);

            const formattedDate = `${day}/${month}/${date.getFullYear()}`;

            const formattedOrder: Order = {
              ID: order.ID,
              ReceivedDateTime: formattedDate,
              ID_Användare_Plockatav: order.ID_Användare_Plockatav,
              Namn: order.Namn,
              Status: order.Status,
            };

            monthArr.push(formattedOrder);
          }
        }

        months.push({ month: self.state.months[previousMonth.getUTCMonth()], data: monthArr });
      }

      years.push({ year: previousYear.getUTCFullYear(), data: months });
    }

    return years;
  }

  showOlder() {
    const display = this.state.display + 1;

    this.setState({
      display,
    }, () => {
      const data = this.loadData(this.state.result || []);

      this.setState({
        data,
      });
    });
  }

  render() {
    const self = this;

    const onclick = function (data: any) {
      self.state.onClickFunction(data);
    };

    const years: { number: number; count: number }[] = [];
    const current = new Date().getFullYear();
    const { oldestOrder } = this.state;
    const difference = current - oldestOrder;

    for (let i = 0; i < difference + 1; i++) {
      let count = 0;

      if (this.state.data.length > 0 && this.state.data[i].data) {
        for (let j = 0; j < this.state.data[i].data.length; j++) {
          count += this.state.data[i].data[j].data.length;
        }
      }

      const year = {
        number: current - i,
        count,
      };

      years.push(year);
    }

    let { display } = this.state;

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <h1>Orderhistorik</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="accordion" id="OrderHistory">
                {this.state.data.map((year, i) => ((year.year <= self.state.startYear.getUTCFullYear() && display !== 0 && display--)
                  ? (
                    <div key={i}>
                      <h3>
                        {(i !== 0 && this.state.display !== display + i) ? <hr /> : ''}
                        {year.year}
                      </h3>
                      {year.data.map((month, j) => (
                        <div rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="Öppna" className="card mb-1 border-bottom" key={j}>
                          <div className="card-header border-0 p-0" id={`heading${j}`}>
                            <h5 className="mb-0">
                              <button className={`btn btn-light justify-content-between text-left w-100 rounded-0 py-3 px-4${(j !== 0) ? ' collapsed' : ''}`} type="button" data-toggle="collapse" data-target={`#collapse${j}`} aria-expanded="false" aria-controls={`collapse${j}`}>
                                {month.month}
                                <span className="badge badge-primary align-middle float-right my-1">{(month.data.length !== 0) ? month.data.length : ''}</span>
                              </button>
                            </h5>
                          </div>
                          <div id={`collapse${j}`} className="collapse multi-collapse">
                            {((month.data.length > 0)
                              ? (
                                <div className="card-body p-0">
                                  <div className="border-top">
                                    <Table clickFunction={onclick} columnSizing={this.state.columnSizing} visualFeedback countRows={false} headers={this.state.headers} data={month.data} skipDataColumns={this.state.skipDataColumns} identifierIndex={0} />
                                  </div>
                                </div>
                              )
                              : '')}
                          </div>
                        </div>
                      ))}
                    </div>
                  )
                  : ''))}
              </div>
              <hr />
              <button onClick={this.showOlder} type="button" className="btn btn-primary btn-lg btn-block">Visa äldre</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderHistory;