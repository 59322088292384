import React, { ChangeEvent, RefObject } from 'react';

interface ProductMessageProps {
  message: string;
  updateMessage: (message: string) => void;
}

class ProductMessage extends React.Component<ProductMessageProps> {
  private productMessageRef: RefObject<HTMLInputElement>;

  constructor(props: ProductMessageProps) {
    super(props);

    this.productMessageRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const message = this.productMessageRef.current?.value || '';

    this.props.updateMessage(message);
  }

  render() {
    return (
      <div className="customer-control">
        <input
          type="text"
          className="form-control"
          ref={this.productMessageRef}
          onChange={this.handleChange}
          value={this.props.message}
          placeholder="Meddelande"
        />
      </div>
    );
  }
}

export default ProductMessage;
