import React, { Component, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Order from './Order';
import Modal from './components/Modal';
import OrdersActive from './components/OrdersActive';
import OrderHistory from './components/OrderHistory';

interface OrdersState {
  openModal: boolean;
  loadOrder: number | null;
  archived: boolean;
}

class Orders extends Component<{}, OrdersState> {
  constructor(props: {}) {
    super(props);

    const self = this;
    this.state = {
      openModal: false,
      loadOrder: null,
      archived: false,
    };
  }

  onClickFunction = (orderid: number, archived: boolean): void => {
    this.setState({
      loadOrder: orderid,
      archived,
      openModal: true,
    });
  };

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  static getDerivedStateFromProps(nextProps: any, prevState: OrdersState): any {
    const loadOrder = prevState.loadOrder || nextProps.loadOrder;

    return {
      loadOrder,
    };
  }

  openOrder(): ReactElement {
    return <Order id={this.state.loadOrder} />;
  }

  setModal(open: boolean): void {
    this.setState({
      openModal: open,
    });
  }

  render(): ReactElement {
    const openSeperate = (
      <Link className="btn btn-primary" target="_blank" to={`/order/${this.state.loadOrder}`}>
        Öppna
      </Link>
    );

    return (
      <div className="row">
        <div className="col">
          {this.state.openModal ? (
            <Modal extraButton={openSeperate} setModal={(open) => this.setModal(open)}>
              {this.openOrder()}
            </Modal>
          ) : (
            ''
          )}
          <div>
            <OrdersActive onClickFunction={(orderid: number) => this.onClickFunction(orderid, false)} />
            <hr className="my-3" />
            <OrderHistory onClickFunction={(orderid) => this.onClickFunction(orderid, true)} />
          </div>
        </div>
      </div>
    );
  }
}

export default Orders;