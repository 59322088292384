import React from 'react';
import { Link } from 'react-router-dom';

interface DiscountedProductProps {
  productID: number;
  data: {
    URL_img: string;
    canBuyPieces: boolean;
    productID: number;
    name: string;
    priceForOne: number;
  };
}

interface DiscountedProductState {
  productID: number;
  URL_img: string;
}

class DiscountedProduct extends React.Component<DiscountedProductProps, DiscountedProductState> {
  constructor(props: DiscountedProductProps) {
    super(props);
    this.state = {
      productID: this.props.productID,
      URL_img: this.props.data.URL_img,
    };
  }

  render() {
    const image = process.env.REACT_APP_IMG_SRC + this.props.data.URL_img;
    const unit = this.props.data.canBuyPieces ? 'kr/st' : 'kr/kg';

    return (
      <div className="col-md-2 mt-4">
        <Link to={`product/${this.props.data.productID}`}>
          <div className="card">
            <div className="card-header header-wrapper">
              <div className="header-content">
                <h5>{this.props.data.name}</h5>
              </div>
            </div>
            <div className="d-flex align-items-center min-height">
              <img
                className={image.includes('logo.png') ? 'card-img-bottom' : 'card-img-top'}
                src={image}
                alt={this.props.data.name}
              />
            </div>
            <h5 className="card-title d-flex justify-content-end mb-0">
              <span className="badge badge-primary">
                Pris:
                {this.props.data.priceForOne}
                {' '}
                {unit}
              </span>
            </h5>
          </div>
        </Link>
      </div>
    );
  }
}

export default DiscountedProduct;
