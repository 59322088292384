import React from 'react';
import TableRow from './TableRow';
import Loading from './Loading.tsx';

class Table extends React.Component{
    constructor(props) {
        super(props);
        
        let hold = this.props.data;
        if(hold && typeof hold === 'object') {
            let arr = [];
            hold.forEach(element => {
                let newElement = Object.keys(element).map(function(k) { return element[k] });
                arr.push(newElement);
            });
            hold = arr;
        }
        this.state = {
            headers: (this.props.headers)? this.props.headers : [[]],
            columnSizing: (this.props.columnSizing)? this.props.columnSizing : [],
            data: (hold)? hold : [[]],
            drawHeaders: [],
            drawData: [],
            drawColumnSizing: [],
            small: (this.props.small === true)? true : false, /* Looks weird, but it's to make sure it is actually set to true, and not a number etc */
            visualFeedback: (this.props.visualFeedback === null)? true : this.props.visualFeedback,

            skipDataColumns: (Array.isArray(this.props.skipDataColumns))? this.props.skipDataColumns : [],
            identifierIndex: this.props.identifierIndex,

            countRows: (this.props.countRows === null)? false : this.props.countRows,
            tooltip: this.props.tooltip || "",
            clickFunction: (!this.props.clickFunction) ? function() {}: this.props.clickFunction,

            hasLoaded: (this.props.hasLoaded === undefined)?  true : this.props.hasLoaded === true,
            
            perPage: this.props.perPage || 0,
            page: 0
        }
    }
    static getDerivedStateFromProps(props, prevState) {
        let skipCol = [],
        identifierIndex = (props.identifierIndex)? props.identifierIndex : prevState.identifierIndex,
        countRows = (props.countRows)? props.countRows : prevState.countRows,
        skipDataColumns = (props.skipDataColumns)? props.skipDataColumns : prevState.skipDataColumns,
        headers = (props.headers)? props.headers : prevState.headers,
        columnSizing = (props.columnSizing)? props.columnSizing : prevState.columnSizing,
        hasLoaded = (props.hasLoaded === undefined)? prevState.hasLoaded: props.hasLoaded,
        perPage = (props.perPage === undefined)? prevState.perPage: props.perPage,
        page = (props.page === undefined)? prevState.page: props.page;
        
        let hold = props.data;
        if(hold.length > 0 && Array.isArray(hold[0])) {
            
        }
        else if(hold.length > 0 && hold[0] != null && typeof hold[0] === 'object') {
            let arr = [];
            hold.forEach(element => {
                let newElement = Object.keys(element).map(function(k) { return element[k] });
                arr.push(newElement);
            });
            hold = arr;
        }
        
        let data = (hold)? hold : prevState.data;

        if(countRows === true) {
            // Adds row number
            headers = ["#"].concat(headers);

            let tempData = [],
            i = 1;

            data.forEach(function ( row ) {
                tempData.push([i].concat(row));
                i++;
            });
            data = tempData;
            
            // Increases skipDataColumns to take the extra column into account
            skipDataColumns.forEach(row => {
                skipCol.push(row + 1);
            });
        }else{
            skipCol = skipDataColumns;
        }

        skipCol = skipCol.sort((a, b) => b - a);

        let drawHeaders = headers;
        let drawData = data;
        let drawColumnSizing = columnSizing;

        if(skipCol.length !== 0) {
            drawHeaders =  Table.generateDrawRow(headers, skipCol);
            drawData =  Table.generateDrawTable(data, skipCol);
            drawColumnSizing = Table.generateColumnSizing(columnSizing, skipCol);
        }

        return (
            {
                headers: props.headers,
                data: props.data,
                skipDataColumns: skipCol,
                countRows: props.countRows,
                identfierIndex: identifierIndex,
                drawHeaders: drawHeaders,
                drawData: drawData,
                drawColumnSizing: drawColumnSizing,
                hasLoaded: hasLoaded,
                perPage: perPage,
                page: page,
            }
        );
    }
    
    addRowNumber(headers, data) {
        let newHeaders = headers.splice(),
        newData = [];

        newHeaders.splice(0, 0, "#");
        
        let rowNumber = 1;
        data.forEach(row => {
            newData.push(row.splice());
            newData[rowNumber-1].splice(0, 0, rowNumber);
            rowNumber++;
        });
        return [newHeaders, newData];
    }
    static generateDrawTable(table, skipCol) {
        let draw = [];
        table.forEach(row => {
            draw.push(this.generateDrawRow(row, skipCol));
        });

        return draw;
    }
    static generateDrawRow(row, skipCol) {
        let draw = [];
        for (let i = 0; i < row.length; i++) {
            const cell = row[i];
            let skip = false;

            skipCol.forEach(index => {
                if(i === index) {
                    skip = true;
                }
            });
            if(!skip) {
                draw.push(cell);
            }
        }
        
        return draw;
    }
    static generateColumnSizing(row, skipCol) {
        let columns = [];
        for (let i = 0; i < row.length; i++) {
            const cell = row[i];
            let skip = false;

            skipCol.forEach(index => {
                if(i === index) {
                    skip = true;
                }
            });
            if(!skip) {
                columns.push(cell);
            }
        }
        
        return columns;
    }

    render() {
        let foo = " table-striped";
        if(this.state.visualFeedback) {
            foo += " table-hover pointer";
        }

        foo += (this.state.small)? " table-sm" : ""
        return (
            <Loading hasLoaded={this.state.hasLoaded}>
                    <table className={ ("table w-100 m-0 bg-white " + foo + ((this.props.className)? " " + this.props.className : ""))}>
                        <thead className="thead-light">
                            <tr>
                                { this.state.drawHeaders.map((header, i) => <th className="border-0" style={(this.state.drawColumnSizing.length === this.state.drawHeaders.length && this.state.drawColumnSizing[i] !== null)? {maxWidth: (this.state.drawColumnSizing[i]), width: (this.state.drawColumnSizing[i])} : {}} key={i}>{ header }</th>) }
                            </tr>
                        </thead>
                        <tbody rel="tooltip" data-toggle="tooltip" data-placement="bottom" title={ this.state.tooltip } >
                            {
                                (this.state.data !== null && this.state.data.length > 0 && this.state.data.length === this.state.drawData.length)?
                                    this.state.drawData.map((order, i) => ((this.state.page * this.state.perPage <= i && ((this.state.page + 1) * this.state.perPage) > i ) || this.state.perPage === 0)? <TableRow inactive={ (this.state.data[i].Inactive !== undefined)? this.state.data[i].Inactive : false} clickFunction={this.state.clickFunction} key={i} identifier={ (Object.prototype.toString.call( this.state.data[i] ) === '[object Array]')? this.state.data[i][this.state.identifierIndex] : this.state.data[i][Object.keys(this.state.data[i])[this.state.identifierIndex]] } row={ order }/> : null )
                                    : null
                            }
                        </tbody>
                    </table>
            </Loading>
        );
    }
}

export default Table;