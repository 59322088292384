import React, { ReactNode } from 'react';

interface DisplayErrorProps {
  type?: string;
  title: string;
  description?: string;
}

class DisplayError extends React.Component<DisplayErrorProps> {
  render(): ReactNode {
    return (
      <div className={((this.props.type) ? `alert alert-${this.props.type}` : 'alert alert-warning')} role="alert">
        <p className="mb-0">
          {this.props.title}
          :
          <br />
          {this.props.description}
        </p>
      </div>
    );
  }
}

export default DisplayError;
