import React, { ReactNode, useEffect, useState } from 'react';

interface SmallLoadingProps {
  children: ReactNode;
  hasLoaded?: boolean;
  title?: string;
}

const SmallLoading: React.FC<SmallLoadingProps> = ({ children, hasLoaded = false, title = '' }) => {
  const [content, setContent] = useState<ReactNode>(children);

  useEffect(() => {
    setContent(children);
  }, [children]);

  return hasLoaded ? (
    <>{content}</>
  ) : (
    <div className="pl-3" style={{ color: 'rgba(0,0,0,0.5)' }}>
      <div className="spinner-border spinner-border-sm text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      {` ${title}`}
    </div>
  );
};

export default SmallLoading;
