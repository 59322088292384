import React, { ReactNode, FormEvent } from 'react';
import SearchBar from './SearchBar';
import axios from '../bin/axios_gsh.js';

interface ProductInfo {
  productID: number;
  itemnr: string;
  categoryID: number;
  weightPerPackage: number;
  sizeOptions: string;
  name: string;
  fav: number;
  origin: string;
  weightPerUnit: number;
  priceForOne: string | null;
  notDividable: boolean;
  priceExits: boolean;
  jmfr_pris: number;
  priceRelText: string;
  vat: number;
  canBuyPieces: boolean;
  URL_img: string;
  selection: boolean;
}

interface ProductInfoResponse {
  length: number;
  data: ProductInfo[];
  status: number;
  statusText: string;
  headers: any;
  config: any;
}

interface SearchBarProps {
  data?: any[]; 
  searchResult?: ReactNode;
  searchString?: string;
  showNumber?: number;
  clickFunction?: (e: any, object: any) => void; 
  inactivated?: boolean;
  refresh: boolean;
  filter: string;
  beginSearch: () => void;
  onSearch: (searchResult: ProductInfo[]) => void;
}

class ProductSearchBar extends SearchBar {
  constructor(props: SearchBarProps) {
    super(props);
    this.state = {
      data: [],
      searchResult: '',
      searchString: '',
      showNumber: props.showNumber || 50,
      parentClickFunction: props.clickFunction || (() => {}),       
      disabled: props.inactivated || false,
      updateResults: false,
    };
  }

  componentDidUpdate(prevProps: SearchBarProps) {
    if (this.props.refresh) {
      this.manualSearch(true);
    }
  }

  search() {
  }

  manualSearch(refresh: boolean = false) {
    const str = this.state.searchString;

    const info = {
      searchString: this.state.searchString,
      filter: this.props.filter,
    };

    if (str && str !== '' && str.length > 1) {
      if (!refresh) {
        this.props.beginSearch();
      }

      axios.post<ProductInfo[]>('product/search/main/', info).then((response) => {
        const arr: ProductInfo[] = [];

        response.data.forEach((element: any) => {
          const productInfo: ProductInfo = {
            productID: element.ProduktID,
            itemnr: element.Nummer,
            categoryID: element.ID_Kategori,
            weightPerPackage: element.Förpackningsstorlek,
            sizeOptions: element.Stegom,
            name: element.Benämning,
            fav: element.favorit ? 1 : 0,
            origin: element.Landskod,
            weightPerUnit: element.Vikt_per_enhet,
            priceForOne: element.Pris !== null ? element.Pris.toFixed(2).replace('.', ',') : null,
            notDividable: element.Ej_Delbar,
            priceExits: element.PrisFinns,
            jmfr_pris: element.jmfr_pris,
            priceRelText: element.PrisText,
            vat: element.moms,
            canBuyPieces: element.Pris_per_styck,
            URL_img: element.URL_Bild,
            selection: element.Avtalsartikel,
          };

          arr.push(productInfo);
        });

        this.setState(
          {
            data: arr,
          },
          () => {
            this.updateList();
          }
        );
      });
    }
  }

  clickSearch(e: FormEvent) {
    e.preventDefault();

    this.manualSearch();
  }

  updateList() {
    const searchResult: ProductInfo[] = [];
    let i = 0;

    if (this.state.data !== null && this.state.data.length > 0) {
      const sorted: { index: number; product: ProductInfo; priority: number }[] = [];
      const searchString = this.state.searchString ? this.state.searchString.toLowerCase() : '';
      let inString = 0;

      this.state.data.forEach((product) => {
        const name = product.name.toLowerCase();

        inString = name.search(searchString);
        if (inString === -1) {
          inString = 999;
        }
        sorted.push({ index: i, product, priority: inString });
        i++;
      });

      sorted.sort((a, b) => {
        const ak = a.priority;
        const bk = b.priority;
        if (ak < bk) return -1;
        if (ak > bk) return 1;
        return 0;
      });

      sorted.forEach((object) => {
        searchResult.push(object.product);
      });
    }
    this.props.onSearch(searchResult);
  }

  render() {
    return (
      <form onSubmit={(e) => this.clickSearch(e)}>
        <div className="mb-3 search-wrapper row no-gutters">
          <div className="input-group input-group-lg">
            <input
              type="text"
              className="form-control rounded-0"
              name="search"
              id="search"
              placeholder="Sök efter en artikel"
              value={this.state.searchString}
              onChange={(e) => this.handleChange(e)}
              disabled={this.state.disabled}
              autoComplete="off"
            />
            <div className="input-group-append">
              <button
                style={{ backgroundSize: '38px 38px' }}
                type="submit"
                rel="tooltip"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Sök"
                className="btn btn-svg search-products icon lg btn-outline-primary btn-lg"
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default ProductSearchBar;