import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from './components/Modal';
import Table from './components/Table';
import DisplayError from './components/DisplayError';
import Pagination from './components/Pagination';
import axios from './bin/axios_gsh';
import { AxiosResponse } from 'axios';

interface Template {
  FullNamn: string | null;
  Namn: JSX.Element | null;
  Form: JSX.Element | null;
}

interface User {
  ID: number;
  Email: string;
  Namn: string;
}

interface ViewState {
  type: string;
  id: number | null;
}

interface State {
  openModal: boolean;
  modal: JSX.Element | null;
  inactive: null;
  userid: number | null;
  companyid: number | null;
  view: ViewState;
  headers: string[];
  skipDataColumns: number[];
  identifierIndex: number;
  hasLoaded: boolean;
  data: Template[];
  templates: any[];
  users: User[];
  error: JSX.Element | null;
  page: number;
  perPage: number;
  clickedTemplate: number | null;
  cartErrorModal: boolean;
  validationErrors: string[];
  admin: boolean;
}

interface Props {
  updateCart: () => void;
}


class OrderSaved extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openModal: false,
      modal: null,
      inactive: null,
      userid: null,
      companyid: null,

      view: {
        type: 'user',
        id: null,
      },

      headers: ['Användare', 'Namn', ''],
      skipDataColumns: [],
      identifierIndex: 0,
      hasLoaded: false,

      data: [],
      templates: [],
      users: [],
      error: null,

      page: 0,
      perPage: 8,
      clickedTemplate: null,

      cartErrorModal: false,
      validationErrors: [],
      admin: false, 
    };

    this.prepareDeleteTemplate = this.prepareDeleteTemplate.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.addTemplate = this.addTemplate.bind(this);
    this.toggleFavorite = this.toggleFavorite.bind(this);
    this.openUserTemplates = this.openUserTemplates.bind(this);
    this.openCompanyTemplates = this.openCompanyTemplates.bind(this);
    this.setModal = this.setModal.bind(this);
    this.setValidationModal = this.setValidationModal.bind(this);
  }

  static getDerivedStateFromProps(prevState: State, nextProps: any) {
    const { access } = nextProps;
    const skipDataColumns = nextProps.skipDataColumns || prevState.skipDataColumns;

    return {
      access,
      skipDataColumns,
    };
  }

  componentWillUnmount() {}

  componentDidMount() {
    const self = this;

    axios.get('user/current').then((response) => {
      const result = response.data;

      if (!result.error) {
        const userid = result.ID;
        const companyid = result.ID_Kund;

        const admin = result.ID_Användare === null;
        self.setState(
          {
            view: {
              type: 'user',
              id: userid,
            },
            userid,
            companyid,
            admin,
          },
          () => {
            self.loadData();
            if (!admin) {
              self.setState({
                users: [
                  {
                    ID: userid,
                    Email: result.Email,
                    Namn: result.Namn,
                  },
                ],
              });
            } else {
              axios.get('user/company/users').then((companyResponse) => {
                const users = companyResponse.data;
                self.setState({
                  users,
                });
              });
            }
          }
        );
      } else {
        self.setState({
          error: <DisplayError {...result.error} />,
        });
      }
    });
  }

  loadData() {
    const self = this;
    const { view } = this.state;

    if (view.type === 'user') {
      axios.get(`order/template/user/${view.id}`).then((response) => {
        const result = response.data;
        if (!result.error) {
          self.setState({
            templates: result,
            hasLoaded: true,
          });
        } else {
          result.hasLoaded = true;
          //this state is never used
          // self.setState({
          //   result,
          // });
        }
      }).then(() => {
        self.updateData();
      });
    } else {
      axios.get('order/template/company').then((response) => {
        const result = response.data;

        if (!result.error) {
          self.setState({
            templates: result,
            hasLoaded: true,
          });
        } else {
          result.hasLoaded = true;

          // self.setState(
          //   result,
          // );
        }
      }).then(() => {
        self.updateData();
      });
    }
  }

  updateData() {
    const self = this;
    const newData: Template[] = [];

    this.state.templates.forEach((template: any) => {
      const ownerOrAdmin = this.state.userid === template.ID_Användare || this.state.admin;
      const newTemplate: Template = {
        FullNamn: null,
        Namn: null,
        Form: null,
      };

      newTemplate.FullNamn = template.FullNamn ? template.FullNamn : '';
      newTemplate.Namn = <Link to={`/lists/list/${template.ID}`}>{template.Namn}</Link>;
      newTemplate.Form = (
        <div>
          {ownerOrAdmin ? <button onClick={self.prepareDeleteTemplate} type="submit" className="btn btn-danger float-right ml-2 icon-custom small" value={template.ID}><img src="/../icon/trash2.0.png" alt="Ta bort" /></button> : ''}
          <button onClick={(e) => { e.preventDefault(); self.setState({ clickedTemplate: template.ID }, () => { self.addToCart(false); }); }} rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="Lägg till i varukorgen" type="submit" className="btn btn-gsh float-right ml-2" value={template.ID}>
            <span className="d-sm-none">Varukorg</span>
            <span className="d-none d-sm-inline">Lägg till</span>
          </button>
          {this.state.admin ? <button onClick={self.toggleFavorite} type="submit" className={`btn float-right ml-2${template.Publik ? ' btn-gsh' : ' btn-light'}`} value={template.ID}>Publicera</button> : ''}
        </div>
      );

      if (ownerOrAdmin) {
        newData.unshift(newTemplate);
      } else {
        newData.push(newTemplate);
      }
    });

    self.setState({
      skipDataColumns: this.state.view.type !== 'company' ? [0] : [],
      data: newData,
    });
  }

  toggleFavorite(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    const self = this;
    const target = e.currentTarget;

    if (target.value) {
      const id = parseInt(target.value, 10);
      let found = false;

      for (let i = 0; i < this.state.templates.length; i++) {
        const order = this.state.templates[i];
        if (order.ID === id) {
          found = true;
          break;
        }
      }

      if (found) {
        axios.post('order/template/favorite', { templateid: id }).then((response) => {
          const result = response.data;
          if (!result.error) {
            self.loadData();
          } else {
            self.setState({
              error: <DisplayError {...result.error} />,
            });
          }
        });
      }
    }
  }

  addTemplate(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const target: any = e.target;

    if (target.templateName) {
      const name = target.templateName.value;

      axios.post('order/template/add/', { name }).then((response) => {
        response = response.data;
      }).then(() => {
        this.loadData();
      });
    }
  }

  deleteTemplate(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    const target = e.currentTarget;

    this.setState({
      openModal: false,
    });
    if (target.value) {
      let found = false;
      const id = parseInt(target.value, 10);

      for (let i = 0; i < this.state.templates.length; i++) {
        const order = this.state.templates[i];
        if (order.ID === id) {
          found = true;
          break;
        }
      }

      if (found) {
        axios.delete('order/template/delete', { data: { templateid: id } }).then((response) => {
          response = response.data;
        }).then(() => {
          this.loadData();
        });
      }
    }
  }

  openUserTemplates(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    const target = e.currentTarget;

    if (target.value) {
      const id = parseInt(target.value, 10);
      let found = false;

      for (let i = 0; i < this.state.users.length; i++) {
        const user = this.state.users[i];
        if (user.ID === id) {
          found = true;
          break;
        }
      }

      if (found) {
        this.setState(
          {
            view: {
              type: 'user',
              id,
            },
            hasLoaded: false,
          },
          () => {
            this.loadData();
          }
        );
      }
    }
  }

  openCompanyTemplates(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    this.setState(
      {
        view: {
          type: 'company',
          id: this.state.companyid,
        },
        hasLoaded: false,
      },
      () => {
        this.loadData();
      }
    );
  }

  prepareDeleteTemplate(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    const extraButton = <button onClick={this.deleteTemplate} type="button" className="btn btn-gsh" value={event.currentTarget.value}>Ta bort</button>;
    const modal = (
      <Modal extraButton={extraButton} setModal={this.setModal} closeButtonText="Avbryt" outsideClickCloses={false}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Ta bort Inköpslistan</h2>
              <p>Är du säker på att du vill ta bort denna Inköpslistan?</p>
            </div>
          </div>
        </div>
      </Modal>
    );

    this.setState({
      openModal: true,
      modal,
    });
  }

  setModal(open: boolean) {
    this.setState({
      openModal: open,
    });
  }

  setValidationModal(open: boolean) {
    this.setState({
      cartErrorModal: open,
    });
  }

  addToCart(force: boolean) {
    const self = this;
    const mallid = this.state.clickedTemplate;

    this.setState({
      openModal: false,
    });

    axios.post('cart/add/template/', { templateID: mallid, force }).then((response: AxiosResponse<any>) => {
      let errors: string[] = [];

      if (response.data.length > 0 && response.data[0].error) {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].error.title === 'Artikeln hittades inte') {
            errors = [];
            errors.push('Ett fel har skett, en eller fler artiklar i varukorgen är utgångna.');
            // Artikeln hittades inte i systemet
            break;
          }

          errors.push(response.data[i].error.data ? `Artikel nr.${response.data[i].error.data.Nummer}, ${response.data[i].error.data.Benämning}: ${response.data[i].error.description}` : `${response.data[i].error.title}: ${response.data[i].error.description}`);
        }
      }

      if (response.data === true) {
        self.props.updateCart();
      }

      self.setState({
        cartErrorModal: self.state.cartErrorModal === false && errors.length !== 0,
        validationErrors: errors,
      });
    });
  }

  render() {
    const orderTable = <Table page={this.state.page} perPage={this.state.perPage} hasLoaded={this.state.hasLoaded} visualFeedback={false} countRows={false} headers={this.state.headers} data={this.state.data} skipDataColumns={this.state.skipDataColumns} identifierIndex={this.state.identifierIndex} />;
    // const description = this.state.acess ? 'Som admin kan du publicera ordrar, då syns ordarna i menyn "Alla" för andra användare i företaget.' : '';
    const self = this;
    const setPage = function (page: number) {
      self.setState({
        page,
      });
    };

    const forceCartButton = <button onClick={(e) => { e.preventDefault(); this.addToCart(true); }} type="button" className="btn btn-gsh" data-dismiss="modal">Lägg till ändå</button>;

    return (
      <div className="row">
        <div className="col">
          {this.state.openModal ? this.state.modal : ''}

          {this.state.cartErrorModal && (
            <Modal outsideClickCloses={false} extraButton={forceCartButton} setModal={this.setValidationModal} closeButtonText="Avbryt">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <h2>Ett fel har skett</h2>
                    <p>Vissa av varorna i listan går inte att lägga till i varukorgen, vill du lägga till varorna ändå? Om du väljer att lägga till varorna i varukorgen ändå så kommer inte alla att komma med.</p>
                    {this.state.validationErrors.map((error, i) => (
                      <p key={i}>{error}</p>
                    ))}
                  </div>
                </div>
              </div>
            </Modal>
          )}
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-8">
                  <h1>Inköpslistor</h1>
                  {/* <p className="lead">{description}</p> */}
                  {this.state.error}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <form onSubmit={this.addTemplate}>
                    <div className="input-group input-group-lg mb-3">
                      <input type="text" className="form-control" name="templateName" id="templateName" placeholder="Namn" required />
                      <div className="input-group-append">
                        <button type="submit" className="btn btn-primary rounded-right">
                          Lägg till
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="border rounded">
                    <div className="px-0 col-md-12">{orderTable}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Pagination setPage={setPage} current={this.state.page} pages={this.state.data.length / this.state.perPage % 1 !== 0 ? Math.floor(this.state.data.length / this.state.perPage) + 1 : Math.floor(this.state.data.length / this.state.perPage)} />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <hr className="d-lg-none mt-4" />
                  <h2 className="text-muted">Användare</h2>
                  <p>Se inköpslistor av andra konton i företaget.</p>
                  <div className="list-group">
                    {this.state.users.map((user, i) => (
                      <button onClick={this.openUserTemplates} type="submit" className={`list-group-item list-group-item-action pointer${this.state.view !== null && this.state.view.id === user.ID ? ' bg-primary text-white' : ''}`} value={user.ID} key={i}>
                        <h6 className="my-0">{user.Namn}</h6>
                        <small className="">{user.Email}</small>
                      </button>
                    ))}
                    <button onClick={this.openCompanyTemplates} className={`list-group-item list-group-item-action pointer${this.state.view !== null && this.state.view.type === 'company' ? ' bg-primary text-white' : ''}`}>
                      Alla
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderSaved;
