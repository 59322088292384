import React, { Component, ReactNode } from 'react';

interface Props {
    row: any[];
    identifier: any;
    clickFunction: (identifier: any) => void;
    inactive?: boolean;
}

interface State {
    row: any[];
    identifier: any;
    clickFunction: (identifier: any) => void;
}

class TableRow extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            row: this.props.row,
            identifier: this.props.identifier,
            clickFunction: this.props.clickFunction
        };
    }

    static getDerivedStateFromProps(props: Props, prevState: State) {
        const row = props.row ? props.row : prevState.row;
        const identifier = props.identifier ? props.identifier : prevState.identifier;
        const clickFunction = props.clickFunction ? props.clickFunction : prevState.clickFunction;

        return {
            row: row,
            identifier: identifier,
            clickFunction: clickFunction
        };
    }

    handleClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        this.state.clickFunction(this.state.identifier);
    }

    render() {
        return (
            <tr className={(this.props.inactive !== undefined && this.props.inactive) ? " table-warning" : ""} onClick={(e) => this.handleClick(e)}>
                {this.state.row.map((cell, i) => <td key={i} className={"align-middle"}>{cell}</td>)}
            </tr>
        );
    }
}

export default TableRow;
