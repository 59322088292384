import React, { Component } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import Order from './Order';

interface AmountFormProps extends RouteComponentProps {
  orderID: number;
  location: any;
}

interface AmountFormState {
  id: number;
  error: boolean;
}

class AmountForm extends Component<AmountFormProps, AmountFormState> {
  constructor(props: AmountFormProps) {
    super(props);
    this.state = {
      id: this.props.orderID,
      error: this.props.location.state !== undefined ? this.props.location.state && this.props.location.state.error : false,
    };
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <div className="alert alert-success" role="alert">
                <h2>Order Mottagen!</h2>
                <p>Tack för din beställning. Ordern kommer nu att processeras och levereras till er på leveransdagen.</p>
                <p>
                  Denna beställning, och era andra, kan ni hitta i menyn under{' '}
                  <Link
                    to="/orders"
                    rel="tooltip"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Klicka här för att se er orderhistorik"
                  >
                    Orderhistorik
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Order id={this.state.id} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AmountForm);